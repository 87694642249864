import React from "react";
import InspectorForm from "../components/short-inspection/inspectorForm";

export default function shortInspectionFormOnline() {
  return (
    <>
      <InspectorForm />
    </>
  );
}
