import React, { useRef, useState, useEffect } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import Modal from "@mui/material/Modal";
import { Alert, Box, Snackbar } from "@mui/material";
import carSkeleton from "../../../images/car-skeleton.png";
import baseUrl from "../../../api/ipconfig";
import { useHistory } from "react-router-dom";

const Step9 = ({ user, userData, step2, setStep2 }) => {
  const token = localStorage.getItem("token");

  const history = useHistory();

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [mileage, setMileage] = useState(null);
  const [engineNo, setEngineNo] = useState(null);
  const [chassisNo, setChassisNo] = useState(null);
  const [registeredNo, setRegisteredNo] = useState(null);
  const [confirmRegisteredNo, setConfirmRegisteredNo] = useState(null);
  const [driveType, setDriveType] = useState(null);
  const [engineType, setEngineType] = useState(null);
  const [color, setColor] = useState(null);
  const [engineCapacity, setEngineCapacity] = useState(null);
  const [transmissionType, setTransmissionType] = useState(null);
  const [registeredCity, setRegisteredCity] = useState(null);
  const [registeredYear, setRegisteredYear] = useState(null);
  const [validRegistration, setValidRegistration] = useState(true);
  const [cities, setCities] = useState(null);
  const [years, setYears] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openCarSkeleton, setOpenCarSkeleton] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [bonnetDefectTypes, setBonnetDefectTypes] = useState([]);
  const [frontBumperDefectTypes, setFrontBumperDefectTypes] = useState([]);
  const [rearRightFenderDefectTypes, setRearRightFenderDefectTypes] = useState(
    []
  );
  const [frontRightFenderDefectTypes, setFrontRightFenderDefectTypes] =
    useState([]);
  const [frontRightDoorDefectTypes, setFrontRightDoorDefectTypes] = useState(
    []
  );
  const [rearRightDoorDefectTypes, setRearRightDoorDefectTypes] = useState([]);
  const [RightFootBandDefectTypes, setRightFootBandDefectTypes] = useState([]);
  const [trunkDefectBtn, setTrunkDefectBtn] = useState(false);
  const [rearBumperDefectBtn, setRearBumperDefectBtn] = useState(false);
  const [rearLeftFenderDefectBtn, setRearLeftFenderDefectBtn] = useState(false);
  const [leftFootBandDefectBtn, setLeftFootBandDefectBtn] = useState(false);
  const [rearLeftDoorDefectBtn, setRearLeftDoorDefectBtn] = useState(false);
  const [frontLeftDoorDefectBtn, setFrontLeftDoorDefectBtn] = useState(false);
  const [frontLeftFenderDefectBtn, setFrontLeftFenderDefectBtn] =
    useState(false);
  const [roofDefectBtn, setRoofDefectBtn] = useState(false);
  const [defectedAreaPic, setDefectedAreaPic] = useState("");
  const [partId, setPartId] = useState("");
  const [bonnetDefectBtn, setBonnetDefectBtn] = useState(false);
  const [frontBumperDefectBtn, setFrontBumperDefectBtn] = useState(false);
  const [frontRightFenderDefectBtn, setFrontRightFenderDefectBtn] =
    useState(false);
  const [roofDefectTypes, setRoofDefectTypes] = useState([]);
  const [leftFootBandDefectTypes, setLeftFootBandDefectTypes] = useState([]);
  const [rearBumperDefectTypes, setRearBumperDefectTypes] = useState([]);
  const [frontLeftFenderDefectTypes, setFrontLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftFenderDefectTypes, setRearLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftDoorDefectTypes, setRearLeftDoorDefectTypes] = useState([]);
  const [frontLeftDoorDefectTypes, setFrontLeftDoorDefectTypes] = useState([]);
  const [trunkDefectTypes, setTrunkDefectTypes] = useState([]);
  const [frontRightDoorDefectBtn, setFrontRightDoorDefectBtn] = useState(false);
  const [rearRightDoorDefectBtn, setRearRightDoorDefectBtn] = useState(false);
  const [rightFootBandDefectBtn, setRightFootBandDefectBtn] = useState(false);
  const [defectId, setDefectId] = useState(null);
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [rearRightFenderDefectBtn, setRearRightFenderDefectBtn] =
    useState(false);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };
  const fetchId = async () => {
    if (initialData === "") {
      try {
        const response = await axios.post(
          `${baseUrl}/ganerateinspection`,
          { userId: user?.id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setInitialData(response?.data);
        setLoading(false);
        console.log("ganerateinspection 3  ", response.data);
        const initialToken = response?.data?.iinitial_token;
        const currentUrl = new URL(window.location.href);

        if (initialToken) {
          currentUrl.searchParams.set("token", initialToken);
          window.history.replaceState({}, "", currentUrl.toString());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (initialData === "") {
  //     fetchId();
  //   }
  // }, []);

  console.log(initialData, "initialData");

  const getAllDefects = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getalldefect`, {
        params: {
          iinitial_token: initialData?.iinitial_token,
          inspectionstep_id: 2,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        if (response?.data?.defect_place === "roof_defect") {
          setRoofDefectTypes(response?.data?.defect_rows);
        }
        if (response?.data?.defect_place === "leftfootboard_defect") {
          setLeftFootBandDefectTypes(response?.data?.defect_rows);
        }
        if (response?.data?.defect_place === "rearbumper_defect") {
          setRearBumperDefectTypes(response?.data?.defect_rows);
        }
        if (response?.data?.bonnet_defect) {
          setBonnetDefectTypes(response?.data?.bonnet_defect);
        } else {
          setBonnetDefectTypes([]);
        }
        if (response?.data?.frontBumper_defect) {
          setFrontBumperDefectTypes(response?.data?.frontBumper_defect);
        } else {
          setFrontBumperDefectTypes([]);
        }
        if (response?.data?.offfrontwing_defect) {
          setFrontRightFenderDefectTypes(response?.data?.offfrontwing_defect);
        } else {
          setFrontRightFenderDefectTypes([]);
        }
        if (response?.data?.offfrontdoor_defect) {
          setFrontRightDoorDefectTypes(response?.data?.offfrontdoor_defect);
        } else {
          setFrontRightDoorDefectTypes([]);
        }
        if (response?.data?.offbackdoor_defect) {
          setRearRightDoorDefectTypes(response?.data?.offbackdoor_defect);
        } else {
          setRearRightDoorDefectTypes([]);
        }
        if (response?.data?.rightfootboard_defect) {
          setRightFootBandDefectTypes(response?.data?.rightfootboard_defect);
        } else {
          setRightFootBandDefectTypes([]);
        }
        if (response?.data?.offbackwing_defect) {
          setRearRightFenderDefectTypes(response?.data?.offbackwing_defect);
        } else {
          setRearRightFenderDefectTypes([]);
        }
        if (response?.data?.rearleftdoor_defect) {
          setRearLeftDoorDefectTypes(response?.data?.rearleftdoor_defect);
        } else {
          setRearLeftDoorDefectTypes([]);
        }
        if (response?.data?.rearleftfender_defect) {
          setRearLeftFenderDefectTypes(response?.data?.rearleftfender_defect);
        } else {
          setRearLeftFenderDefectTypes([]);
        }
        if (response?.data?.frontleftfender_defect) {
          setFrontLeftFenderDefectTypes(response?.data?.frontleftfender_defect);
        } else {
          setFrontLeftFenderDefectTypes([]);
        }
        if (response?.data?.trunk_defect) {
          setTrunkDefectTypes(response?.data?.trunk_defect);
        } else {
          setTrunkDefectTypes([]);
        }
        if (response?.data?.frontleftdoor_defect) {
          setFrontLeftDoorDefectTypes(response?.data?.frontleftdoor_defect);
        } else {
          setFrontLeftDoorDefectTypes([]);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const getDefects = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getdefectimages`, {
        params: {
          iinitial_token: initialData?.iinitial_token,
          defect_place: partId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response?.data?.defect_place === "bonnet_defect") {
          setBonnetDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "frontBumper_defect") {
          setFrontBumperDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "offfrontwing_defect") {
          setFrontRightFenderDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "offfrontdoor_defect") {
          setFrontRightDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "offbackdoor_defect") {
          setRearRightDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rightfootboard_defect") {
          setRightFootBandDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "offbackwing_defect") {
          setRearRightFenderDefectTypes(response?.data?.defect_rows);
        }
        if (response?.data?.defect_place === "roof_defect") {
          setRoofDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "frontleftfender_defect") {
          setFrontLeftFenderDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "leftfootboard_defect") {
          setLeftFootBandDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearbumper_defect") {
          setRearBumperDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearleftdoor_defect") {
          setRearLeftDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "frontleftdoor_defect") {
          setFrontLeftDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "trunk_defect") {
          setTrunkDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearleftfender_defect") {
          setRearLeftFenderDefectTypes(response?.data?.defect_rows);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const defectsTypes = async () => {
    setPending(true);
    setPendingData("Uploading Defect...");
    const formData = new FormData();

    const options = {
      maxSizeMB: 0.06,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    formData.append("defect_place", partId);
    formData.append("defect_type", defectedAreaPic);
    formData.append("iinitial_token", initialData?.iinitial_token);
    formData.append("inspectionstep_id", 2);

    try {
      const response = await axios.post(`${baseUrl}/defects`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      //console.log(response?.data);

      if (response?.data) {
        getDefects();
        setSuccessOpen(true);
        setSuccess(response?.data);
      }

      setDefectedAreaPic("");
    } catch (error) {
      setError(error.response.data?.error);
      setPendingData("Defect Uploading failed");
      setErrOpen(true);
    }
    setPending(false);
  };

  const deleteDefect = async () => {
    try {
      const formData = new FormData();
      formData.append("defect_id", defectId?.id);

      const response = await axios.post(`${baseUrl}/defectdelete`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        getAllDefects();
        setOpenDeleteModal(false);

        if (response?.data) {
          getDefects();
          setSuccessOpen(true);
          setSuccess(response?.data?.message);
        }
      } else {
        setErrOpen(true);
        setError("Delete failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting defect:", error);
      setError(error.response.data?.error);
      setErrOpen(true);
    }
  };

  const handleDeleteDefect = (type) => {
    setOpenDeleteModal(true);
    setDefectId(type);
  };
  const handleRegistrationNumber = (e) => {
    const inputValue = e.target.value;
    const registrationRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{1,3}-\d{1,4}$/;

    setRegisteredNo(inputValue);
    if (inputValue.length <= 8 && registrationRegex.test(inputValue)) {
      //console.log("valid registration number");
      setValidRegistration(true);
    } else {
      //console.log("Invalid registration number");
      setValidRegistration(false);
    }
  };

  console.log(initialData?.iinitial_token, "token");

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const url = window.location.search;
      const postId = extractTokenFromUrl(url, "postId");
      const inspection_id = extractTokenFromUrl(url, "inspection");
      const short_token = extractTokenFromUrl(url, "token");

      const CreateUser = async () => {
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append("post_id", postId);
          params.data.append("shortinspection_id", inspection_id);

          params.data.append("shortinspection_token", short_token);
          // params.data.append("post_id", 2462);

          try {
            const response = await axios.post(
              `${baseUrl}/saveshortinspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response?.data) {
              setSuccessOpen(true);
              setSuccess("Step 2 Completed");
            }

            setIsSubmitting(false);
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true);
            setError(`Step 2 Submit Failed!`);
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 2");
          setTimeout(() => {
            window.open(
              `https://inspectionreport.famewheels.com/inspection-report/short/${short_token}`
            );
            history.push("/");
          }, 1000);
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [token, user?.id]
  );

  const defectsArr = [
    {
      value: "P-0.55",
      name: "P",
    },
    {
      value: "A1-0.005",
      name: "A1",
    },
    {
      value: "A2-0.0314786",
      name: "A2",
    },
    {
      value: "A3-0.056",
      name: "A3",
    },
    {
      value: "B1-0.005",
      name: "B1",
    },
    {
      value: "B2-0.0314786",
      name: "B2",
    },
    {
      value: "B3-0.056",
      name: "B3",
    },
    {
      value: "C1-0.005",
      name: "C1",
    },
    {
      value: "C2-0.022508",
      name: "C2",
    },
    {
      value: "D1-0.005",
      name: "D1",
    },
    {
      value: "D2-0.0314786",
      name: "D2",
    },
    {
      value: "D3-0.056",
      name: "D3",
    },
    {
      value: "E1-0",
      name: "E1",
    },
    {
      value: "E2-0",
      name: "E2",
    },
    {
      value: "E3-0",
      name: "E3",
    },
    {
      value: "S1-0.005",
      name: "S1",
    },
    {
      value: "S2-0.022508",
      name: "S2",
    },
    {
      value: "W1-0",
      name: "W1",
    },
    {
      value: "W2-0",
      name: "W2",
    },
    {
      value: "W3-0",
      name: "W3",
    },
    {
      value: "Y1-0.005",
      name: "Y1",
    },
    {
      value: "Y2-0.022508",
      name: "Y2",
    },
    {
      value: "Y3-0.03",
      name: "Y3",
    },
    {
      value: "X-0.03",
      name: "X",
    },
    {
      value: "XX-0",
      name: "XX",
    },
    {
      value: "H-0.03004",
      name: "H",
    },
  ];

  const updateCarPart = (field, value) => {
    setStep2((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${baseUrl}/cities`);

        setCities(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchYears = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getModelYear`);

        // //console.log("All Cities", response.data);
        //console.log("years ======== > ", response.data);
        setYears(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCity();
    fetchYears();
  }, []);

  const colorsData = {
    colors: [
      {
        name: "white",
        code: "#FFFFFF",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "black",
        code: "#000000",
      },
      {
        name: "Grey",
        code: "#888888",
      },
      {
        name: "blue",
        code: "#000053",
      },
      {
        name: "green",
        code: "#0B780B",
      },
      {
        name: "red",
        code: "#FF0000",
      },
      {
        name: "gold",
        code: "#FFD700",
      },
      {
        name: "maroon",
        code: "#800000",
      },
      {
        name: "beige",
        code: "#F5F5DC",
      },
      {
        name: "pink",
        code: "#DA55AD",
      },
      {
        name: "brown",
        code: "#966938",
      },
      {
        name: "burgundy",
        code: "#6C1C1F",
      },
      {
        name: "yellow",
        code: "#FFFF00",
      },
      {
        name: "bronze",
        code: "#574327",
      },
      {
        name: "purple",
        code: "#57105C",
      },
      {
        name: "Turqouise",
        code: "#22EEE3",
      },
      {
        name: "orange",
        code: "#FE4F09",
      },
      {
        name: "indigo",
        code: "#3A125E",
      },
      {
        name: "magneta",
        code: "#65183A",
      },
      {
        name: "other",
        code: "#FFFFFF",
      },
    ],
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        // onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <p className="text-center">
            Are You Sure You Want To Delete {defectId?.defect_type} Defect ?
          </p>

          <Button
            className="bg-danger text-white border-0 text-center fw-600"
            onClick={deleteDefect}
            fullWidth
          >
            Delete
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openCarSkeleton}
        onClose={() => setOpenCarSkeleton(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <div className="position-relative">
            <img
              src={carSkeleton}
              className=" object-fit-contain w-100"
              alt="car-skeleton"
            />

            {bonnetDefectTypes && (
              <div
                onClick={() => partId === "bonnet_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "32.5%",
                  bottom: "16%",
                  width: "35%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {bonnetDefectTypes &&
                    bonnetDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={type?.id}
                          className="bg-danger rounded-pill text-white border-0 text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                            zIndex: 99999,
                          }}
                        >
                          {type?.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontBumperDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontBumper_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "29%",
                  bottom: "0%",
                  width: "42%",
                  height: "12%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontBumperDefectTypes &&
                    frontBumperDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "20px",
                            height: "20px",
                            fontSize: "10px",
                            zIndex: 99999,
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontRightFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "offfrontwing_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "13%",
                  bottom: "15%",
                  width: "10%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontRightFenderDefectTypes &&
                    frontRightFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontRightDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "offfrontdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "5%",
                  bottom: "34%",
                  width: "18.5%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontRightDoorDefectTypes &&
                    frontRightDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearRightDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "offbackdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "6%",
                  top: "35%",
                  width: "17%",
                  height: "12.5%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearRightDoorDefectTypes &&
                    rearRightDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearRightFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "offbackwing_defect" && setOpenList(true)
                }
                className=" border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "6%",
                  top: "17%",
                  width: "17%",
                  height: "15%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearRightFenderDefectTypes &&
                    rearRightFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {RightFootBandDefectTypes && (
              <div
                onClick={() =>
                  partId === "rightfootboard_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "1%",
                  top: "37%",
                  width: "6%",
                  height: "30%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {RightFootBandDefectTypes &&
                    RightFootBandDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="rounded-pill text-white text-center fw-600 bg-danger"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {roofDefectTypes && (
              <div
                onClick={() => partId === "roof_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "36%",
                  top: "35%",
                  width: "28%",
                  height: "22%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {roofDefectTypes &&
                    roofDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftdoor_defect" && setOpenList(true)
                }
                className=" border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "8%",
                  bottom: "34%",
                  width: "18%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftDoorDefectTypes &&
                    frontLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {trunkDefectTypes && (
              <div
                onClick={() => partId === "trunk_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "32.5%",
                  top: "15%",
                  width: "35%",
                  height: "8%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {trunkDefectTypes &&
                    trunkDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {leftFootBandDefectTypes && (
              <div
                onClick={() =>
                  partId === "leftfootboard_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "2.5%",
                  top: "37%",
                  width: "6%",
                  height: "30%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {leftFootBandDefectTypes &&
                    leftFootBandDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearBumperDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearbumper_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "30%",
                  top: "3%",
                  width: "40%",
                  height: "9%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearBumperDefectTypes &&
                    rearBumperDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  top: "17.5%",
                  width: "13%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftFenderDefectTypes &&
                    rearLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  bottom: "16.5%",
                  width: "12%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftFenderDefectTypes &&
                    frontLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "7%",
                  top: "35%",
                  width: "26%",
                  height: "13%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftDoorDefectTypes &&
                    rearLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={openList}
        onClose={() => {
          setOpenList(false);
        }}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <select
            aria-label="Default select example"
            className="form-select btntextSize"
            onChange={(e) => setDefectedAreaPic(e.target.value)}
          >
            <option value="">Select</option>
            {defectsArr?.map((defect) => (
              <option value={defect.value}>{defect.name}</option>
            ))}
          </select>

          <Button
            className="color-white LegendIcon bg p-2 text-capitalize"
            fullWidth
            disabled={partId && defectedAreaPic !== "" ? false : true}
            onClick={() => {
              defectsTypes();
              setOpenList(false);
            }}
          >
            Create
          </Button>
        </Box>
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form
            onBlur={fetchId}
            ref={formRef}
            onSubmit={onSubmit}
            className="postAdForm"
          >
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehicleKM"
                  className="form-label text-black  btntextSize "
                >
                  Mileage * (km)
                </label>
                <div className="input-group">
                  <input
                    name="shortinspection_mileage"
                    type="number"
                    className="form-control btntextSize"
                    id="vehicleKM"
                    placeholder="Mileage"
                    min="0"
                    required
                    value={mileage}
                    onChange={(e) => setMileage(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize"
                >
                  Engine No.
                </label>
                <div className="input-group">
                  <input
                    name="shortinspection_engineno"
                    type="text"
                    className="form-control btntextSize"
                    id="post-title"
                    aria-describedby="titleHelp"
                    placeholder="Engine No."
                    value={engineNo}
                    onChange={(e) => setEngineNo(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize "
                >
                  Chassis No.
                </label>
                <div className="input-group">
                  <input
                    name="shortinspection_chasisno"
                    type="text"
                    className="form-control btntextSize"
                    id="post-title"
                    aria-describedby="titleHelp"
                    required
                    placeholder="Chassis No."
                    value={chassisNo}
                    onChange={(e) => setChassisNo(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize"
                >
                  Registered No.
                </label>
                <input
                  name="shortinspection_registrationno"
                  type="text"
                  className="form-control btntextSize text-uppercase"
                  id="post-title"
                  aria-describedby="titleHelp"
                  required
                  maxLength={8}
                  placeholder="abc-1234"
                  value={registeredNo}
                  onChange={handleRegistrationNumber}
                />

                {!validRegistration && (
                  <p
                    className="color-secondary mt-1"
                    style={{ fontSize: "12px" }}
                  >
                    Should have Format of ABC-1234
                  </p>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize "
                >
                  Confirm Registered No.
                </label>
                <input
                  type="text"
                  className="form-control btntextSize text-uppercase"
                  id="post-title"
                  aria-describedby="titleHelp"
                  required
                  placeholder="abc-1234"
                  value={confirmRegisteredNo}
                  onChange={(e) => setConfirmRegisteredNo(e.target.value)}
                />
                {registeredNo !== confirmRegisteredNo && (
                  <p className="color-secondary" style={{ fontSize: "12px" }}>
                    Registered No and Confirm Registered No Should be same
                  </p>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehicleDriveType"
                  className="form-label text-black  btntextSize "
                >
                  Drive Type
                </label>
                <select
                  name="shortinspection_drivetype"
                  className="form-select btntextSize"
                  id="vehicleDriveType"
                  aria-label="Default select example"
                  required
                  value={driveType}
                  onChange={(e) => setDriveType(e.target.value)}
                >
                  <option selected value="">
                    Select Drive Type
                  </option>
                  <option value="2WD">2WD</option>
                  <option value="4WD">4WD</option>
                  <option value="AWD">AWD</option>
                </select>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehicleEngineType"
                  className="form-label text-black  btntextSize "
                >
                  Engine Type
                </label>
                <select
                  name="shortinspection_enginetype"
                  className="form-select btntextSize"
                  id="vehicleEngineType"
                  aria-label="Default select example"
                  required
                  value={engineType}
                  onChange={(e) => setEngineType(e.target.value)}
                >
                  <option selected value="">
                    Select Engine Type
                  </option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Electric">Electric</option>
                </select>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="shortinspection_color"
                  className="form-label text-black  btntextSize "
                >
                  Exterior Colour
                </label>

                <select
                  name="shortinspection_color"
                  className="form-select btntextSize"
                  id="shortinspection_color"
                  aria-label="Default select example"
                  required
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  <option selected value="">
                    Select Color
                  </option>
                  {colorsData.colors?.map((color) => (
                    <option value={color.name}>{color.name}</option>
                  ))}
                </select>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize "
                >
                  Engine Capacity
                </label>
                <input
                  name="shortinspection_enginecapacity"
                  type="number"
                  className="form-control btntextSize"
                  id="post-title"
                  aria-describedby="titleHelp"
                  required
                  placeholder="Engine Capacity"
                  value={engineCapacity}
                  onChange={(e) => setEngineCapacity(e.target.value)}
                />
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehicleTransmission"
                  className="form-label text-black  btntextSize"
                >
                  Transmission Type
                </label>
                <select
                  name="shortinspection_transmission"
                  className="form-select btntextSize"
                  id="vehicleTransmission"
                  aria-label="Default select example"
                  required
                  value={transmissionType}
                  onChange={(e) => setTransmissionType(e.target.value)}
                >
                  <option selected value="">
                    Select Transmission
                  </option>
                  <option value="Automatic">Automatic</option>
                  <option value="Manual">Manual</option>
                </select>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="field1"
                  className="form-label text-black  btntextSize "
                >
                  Registered City
                </label>
                <select
                  name="shortinspection_city"
                  className="form-select btntextSize"
                  id="post-city"
                  aria-label="Default select example"
                  required
                  value={registeredCity}
                  onChange={(e) => setRegisteredCity(e.target.value)}
                >
                  <option value="">Select City</option>
                  {cities &&
                    cities.map((item) => (
                      <option key={item?.cityID} value={item?.cityName}>
                        {item?.cityName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="post-year"
                  className="form-label text-black  btntextSize "
                >
                  Registered Year
                </label>
                <select
                  name="shortinspection_registrationyear"
                  className="form-select btntextSize"
                  id="post-year"
                  aria-label="Default select example"
                  required
                  value={registeredYear}
                  onChange={(e) => setRegisteredYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {years &&
                    years.map((item) => (
                      <option key={item?.yearId} value={item?.year}>
                        {item?.year}
                      </option>
                    ))}
                </select>
              </div>
              {/* front bumper */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    htmlFor="shortinspection_fbumper"
                    className="form-label btntextSize text-black "
                  >
                    Front Bumper
                  </label>
                  <div>
                    <select
                      name="shortinspection_fbumper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontBumper}
                      onChange={(e) => {
                        updateCarPart("frontBumper", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontBumperDefectBtn(true);
                        } else {
                          setFrontBumperDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontBumperDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontBumper === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontBumper_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}

                    <span
                      className="d-flex justify-content-between align-items-center"
                      id="basic-addon2"
                    ></span>
                  </div>
                </div>
              </div>

              {/* bonnet  */}
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_bonnet"
                    className="form-label btntextSize text-black "
                  >
                    Bonnet
                  </label>
                  <div>
                    <select
                      name="shortinspection_bonnet"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.bonnet}
                      onChange={(e) => {
                        updateCarPart("bonnet", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setBonnetDefectBtn(true);
                        } else {
                          setBonnetDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">OK</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {bonnetDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.bonnet === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("bonnet_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* right front fender */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_frfender"
                    className="form-label btntextSize text-black "
                  >
                    Front Right Fender
                  </label>
                  <div>
                    <select
                      name="shortinspection_frfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontRightFender}
                      onChange={(e) => {
                        updateCarPart("frontRightFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontRightFenderDefectBtn(true);
                        } else {
                          setFrontRightFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontRightFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontRightFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offfrontwing_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* front right door  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_frdoor"
                    className="form-label btntextSize text-black "
                  >
                    Front Right Door
                  </label>
                  <div>
                    <select
                      name="shortinspection_frdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontRightDoor}
                      onChange={(e) => {
                        updateCarPart("frontRightDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontRightDoorDefectBtn(true);
                        } else {
                          setFrontRightDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontRightDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontRightDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offfrontdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* rear right door  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_rrdoor"
                    className="form-label btntextSize text-black "
                  >
                    Rear Right Door
                  </label>
                  <div>
                    <select
                      name="shortinspection_rrdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightDoor}
                      onChange={(e) => {
                        updateCarPart("rearRightDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearRightDoorDefectBtn(true);
                        } else {
                          setRearRightDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearRightDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearRightDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offbackdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* right footband  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_rfboard"
                    className="form-label btntextSize text-black "
                  >
                    Right Foot board
                  </label>
                  <div>
                    <select
                      name="shortinspection_rfboard"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rightFootBand}
                      onChange={(e) => {
                        updateCarPart("rightFootBand", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRightFootBandDefectBtn(true);
                        } else {
                          setRightFootBandDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rightFootBandDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rightFootBand === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rightfootboard_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* rear right fender  */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="shortinspection_rrfender"
                    className="form-label btntextSize text-black "
                  >
                    Rear Right Fender
                  </label>
                  <div>
                    <select
                      name="shortinspection_rrfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearRightFender}
                      onChange={(e) => {
                        updateCarPart("rearRightFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearRightFenderDefectBtn(true);
                        } else {
                          setRearRightFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value={"Ok"}>Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearRightFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearRightFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("offbackwing_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_trunk"
                    className="form-label btntextSize text-black"
                  >
                    Trunk
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_trunk"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.trunk}
                      onChange={(e) => {
                        updateCarPart("trunk", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setTrunkDefectBtn(true);
                        } else {
                          setTrunkDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {trunkDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.trunk === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("trunk_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {/* rear bumper  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_rbumper"
                    className="form-label btntextSize text-black"
                  >
                    Rear Bumper
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_rbumper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearBumper}
                      onChange={(e) => {
                        updateCarPart("rearBumper", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearBumperDefectBtn(true);
                        } else {
                          setRearBumperDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearBumperDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearBumper === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearbumper_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_rlfender"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_rlfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.rearLeftFender}
                      onChange={(e) => {
                        updateCarPart("rearLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftFenderDefectBtn(true);
                        } else {
                          setRearLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_rldoor"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_rldoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      value={step2.rearLeftDoor}
                      onChange={(e) => {
                        updateCarPart("rearLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftDoorDefectBtn(true);
                        } else {
                          setRearLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.rearLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_fldoor"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_fldoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontLeftDoor}
                      onChange={(e) => {
                        updateCarPart("frontLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftDoorDefectBtn(true);
                        } else {
                          setFrontLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              {/* front left fender  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_flfender"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_flfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.frontLeftFender}
                      onChange={(e) => {
                        updateCarPart("frontLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftFenderDefectBtn(true);
                        } else {
                          setFrontLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.frontLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="shortinspection_roof"
                    className="form-label btntextSize text-black"
                  >
                    Roof
                  </label>
                  <div className="input-group">
                    <select
                      name="shortinspection_roof"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step2.roof}
                      onChange={(e) => {
                        updateCarPart("roof", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRoofDefectBtn(true);
                        } else {
                          setRoofDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {roofDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step2.roof === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("roof_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-center pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Step9;
