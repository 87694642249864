import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import { Form } from "react-bootstrap";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step5 = ({ onSuccess, previouse, user, userData, step6, setStep6 }) => {
  const token = localStorage.getItem("token");

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [stepImages, setStepImages] = useState(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);
  const [isSycc, setIsSync] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");
  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 1500);
  }, [syncable]);

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          // const formData = new FormData();

          // // Append common form data fields
          // formData.append("iinitial_token", StepToken);
          // formData.append("inspectionstep_id", 2);

          // // Append each image to formData
          // formData.append(`file[]`, image.image);
          // formData.append(`image_name`, image.name); // Assuming name is an array

          // //console.log("FormData:", formData.get("image_name"));

          // const response = await axios.post(uploadEndpoint, formData, {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          //console.log("API Response:", response.data);


          const s3Key = `public/inspection/${StepToken}/${image?.name}/${image?.image?.name}`;
  
          const params = {
            Bucket: 'famewheels',
            Key: s3Key,
            Body: image.image,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: image.image.type,
          };
  
          const response = await s3.upload(params).promise();

          const url = response.Key;
 
           const ImageName = url.substring(url.lastIndexOf('/') + 1);
 
           const formData = new FormData();
           formData.append("image_name", image?.name);
           formData.append("iinitial_token", StepToken);
           formData.append("inspectionstep_id", 6);
           formData.append(`file`, ImageName);
 
           const responseApi = await axios.post(
             `${baseUrl}/saveinspectionimage`,
             formData,
             {
               headers: {
                 Authorization: `Bearer ${token}`,
                 "Content-Type": "multipart/form-data",
               },
             }
           );

          await handleDeleteImage(image.id, image.id);
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleImageGallery = () => {
    setOpenImageGallery(true);
    // setGalleryImages(images);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 6);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = filteredImagesData.filter(
        (images) => images.inspection_step === 6
      );

      setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    getLocalImages();
  }, []);

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_sixth`);
      if (formData) {
        const newData = JSON.parse(formData);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    // Open the IndexedDB database
    const db = await openDB("my-database", 1, {
      upgrade(db) {
        // Check if the object store exists before creating it
        if (!db.objectStoreNames.contains("images")) {
          // Create an object store for storing images
          db.createObjectStore("images", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });

    // Iterate over each file
    for (const file of files) {
      const options = {
        maxSizeMB: 0.06,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      //console.log("Compressed file:", compressedFile);

      const compressedImageFile = new File([compressedFile], file.name, {
        type: file.type,
      });

      const imageData = {
        name: fieldName,
        image: compressedImageFile,
        token: StepToken,
        inspection_step: 6,
      };

      try {
        // Store the image data in IndexedDB
        const tx = db.transaction("images", "readwrite");
        const store = tx.objectStore("images");
        await store.add(imageData);
        await tx.done;
        //console.log("Image uploaded to IndexedDB successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Retrieve the stored images from IndexedDB
    await getLocalImages();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "6";
            obj.token = StepToken;
          }
        });

        //console.log("inspectionDraftArray === ?>>> ",inspectionDraftArray);

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_sixth`, serializedFormData);

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 2");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const Create = async () => {
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("userId", userData?.id);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse?.iinitial_id
        );
        params.data.append("iinitial_token", StepToken);
        // params.data.append("is_edit", 0);

        Object.entries(images).forEach(([fieldName, file]) => {
          params.data.append(fieldName, file);
        });
        try {
          const response = await axios.post(
            `${baseUrl}/savetyreeeinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response?.data) {
            setSuccessOpen(true);
            setSuccess("Step 6 Completed");
            setTimeout(() => {
              onSuccess();
            }, 500);
          }

          // localStorage.removeItem(`${StepToken}_sixth`)

          //console.log("====================================");
          //console.log(response);
          setIsSubmitting(false);
          //console.log("====================================");
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 6 Submit Failed!`);
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setIsSubmitting(true);
        setIsSubmittingFields(true);
        setPending(true);
        setPendingData("Completing Step 6");
        await Create();
      } catch (e) {
        console.error(e);
      } finally {
        setPending(false);
        setIsSubmitting(false);
        setIsSubmittingFields(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      await postImages();
      await handleSaveFields();
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 500);
    }
  }, [isSycc]);

  const updateCarPart = (part, value) => {
    setStep6((prevState) => {
      const updatedStep6 = {
        ...prevState,
        [part]: value,
      };
      localStorage.setItem(`${StepToken}_sixth`, JSON.stringify(updatedStep6));
      return updatedStep6;
    });
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_acoperational"
                    className="form-label btntextSize text-black"
                  >
                    AC Operational
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_acoperational"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.acOperational}
                      onChange={(e) =>
                        updateCarPart("acOperational", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_acoperationalimage &&
                      images.ityre_acoperationalimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_acoperationalimage);
                            setImageId("ityre_acoperationalimage");
                            getImages("ityre_acoperationalimage");
                          }}
                        >
                          {images.ityre_acoperationalimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_acoperationalimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_acoperationalimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_acoperationalimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_acoperationalimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_rightseatadjusterrecliner"
                    className="form-label btntextSize text-black"
                  >
                    Right Seat Adjuster Recliner
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rightseatadjusterrecliner"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightseatadjusterrecliner}
                      onChange={(e) =>
                        updateCarPart(
                          "rightseatadjusterrecliner",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working or Broken">
                        Not Working or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rightseatadjusterreclinerimage &&
                      images.ityre_rightseatadjusterreclinerimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rightseatadjusterreclinerimage
                            );
                            setImageId("ityre_rightseatadjusterreclinerimage");
                            getImages("ityre_rightseatadjusterreclinerimage");
                          }}
                        >
                          {images.ityre_rightseatadjusterreclinerimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatadjusterreclinerimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightseatadjusterreclinerimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatadjusterreclinerimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightseatadjusterreclinerimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_rightseatadjusterleartrack"
                    className="form-label btntextSize text-black"
                  >
                    Right Seat Adjuster Lear Track
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rightseatadjusterleartrack"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightseatadjusterleartrack}
                      onChange={(e) =>
                        updateCarPart(
                          "rightseatadjusterleartrack",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Moving or Broken">
                        Not Moving or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rightseatadjusterleartrackimage &&
                      images.ityre_rightseatadjusterleartrackimage.length >
                        0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rightseatadjusterleartrackimage
                            );
                            setImageId("ityre_rightseatadjusterleartrackimage");
                            getImages("ityre_rightseatadjusterleartrackimage");
                          }}
                        >
                          {images.ityre_rightseatadjusterleartrackimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatadjusterleartrackimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightseatadjusterleartrackimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatadjusterleartrackimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightseatadjusterleartrackimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rightfrontseatelectricfunction"
                    className="form-label btntextSize text-black"
                  >
                    Right Front Seat Electric Function
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rightfrontseatelectricfunction"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightfrontseatelectricfunction}
                      onChange={(e) =>
                        updateCarPart(
                          "rightfrontseatelectricfunction",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rightfrontseatelectricfunctionimage &&
                      images.ityre_rightfrontseatelectricfunctionimage.length >
                        0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rightfrontseatelectricfunctionimage
                            );
                            setImageId(
                              "ityre_rightfrontseatelectricfunctionimage"
                            );
                            getImages(
                              "ityre_rightfrontseatelectricfunctionimage"
                            );
                          }}
                        >
                          {
                            images.ityre_rightfrontseatelectricfunctionimage
                              .length
                          }{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightfrontseatelectricfunctionimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightfrontseatelectricfunctionimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightfrontseatelectricfunctionimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rightfrontseatelectricfunctionimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_frontrightpowerwindow"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Power Window/Manual Lever
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontrightpowerwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontrightpowerwindow}
                      onChange={(e) =>
                        updateCarPart("frontrightpowerwindow", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working Properly">Working Properly</option>
                      <option value="Not Working Properly">
                        Not Working Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontrightpowerwindowimage &&
                      images.ityre_frontrightpowerwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontrightpowerwindowimage
                            );
                            setImageId("ityre_frontrightpowerwindowimage");
                            getImages("ityre_frontrightpowerwindowimage");
                          }}
                        >
                          {images.ityre_frontrightpowerwindowimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightpowerwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightpowerwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightpowerwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightpowerwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_windowsafetylock"
                    className="form-label btntextSize text-black"
                  >
                    Window Safety Lock
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_windowsafetylock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.windowsafetylock}
                      onChange={(e) =>
                        updateCarPart("windowsafetylock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_windowsafetylockimage &&
                      images.ityre_windowsafetylockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_windowsafetylockimage
                            );
                            setImageId("ityre_windowsafetylockimage");
                            getImages("ityre_windowsafetylockimage");
                          }}
                        >
                          {images.ityre_windowsafetylockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_windowsafetylockimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_windowsafetylockimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_windowsafetylockimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_windowsafetylockimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_frontrightseatposhish"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Seat Poshish
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontrightseatposhish"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontrightseatposhish}
                      onChange={(e) =>
                        updateCarPart("frontrightseatposhish", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Not Checked due to Market Cover">
                        Not Checked due to Market Cover
                      </option>
                      <option value="Dirty">Dirty</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontrightseatposhishimage &&
                      images.ityre_frontrightseatposhishimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontrightseatposhishimage
                            );
                            setImageId("ityre_frontrightseatposhishimage");
                            getImages("ityre_frontrightseatposhishimage");
                          }}
                        >
                          {images.ityre_frontrightseatposhishimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightseatposhishimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightseatposhishimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightseatposhishimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightseatposhishimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_frontrightdoorwindow"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Door Window
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontrightdoorwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontrightdoorwindow}
                      onChange={(e) =>
                        updateCarPart("frontrightdoorwindow", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontrightdoorwindowimage &&
                      images.ityre_frontrightdoorwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontrightdoorwindowimage
                            );
                            setImageId("ityre_frontrightdoorwindowimage");
                            getImages("ityre_frontrightdoorwindowimage");
                          }}
                        >
                          {images.ityre_frontrightdoorwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightdoorwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightdoorwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontrightdoorwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontrightdoorwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Right Seat Belt
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rightseatbelt"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightseatbelt}
                      onChange={(e) =>
                        updateCarPart("rightseatbelt", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rightseatbeltimage &&
                      images.ityre_rightseatbeltimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_rightseatbeltimage);
                            setImageId("ityre_rightseatbeltimage");
                            getImages("ityre_rightseatbeltimage");
                          }}
                        >
                          {images.ityre_rightseatbeltimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatbeltimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rightseatbeltimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightseatbeltimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rightseatbeltimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_rearviewmirrordimmer"
                    className="form-label btntextSize text-black"
                  >
                    Rear View Mirror Dimmer
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearviewmirrordimmer"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearviewmirrordimmer}
                      onChange={(e) =>
                        updateCarPart("rearviewmirrordimmer", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Showing Reflection">
                        Showing Reflection
                      </option>
                      <option value="Not Showing Reflection">
                        Not Showing Reflection
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearviewmirrordimmerimage &&
                      images.ityre_rearviewmirrordimmerimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearviewmirrordimmerimage
                            );
                            setImageId("ityre_rearviewmirrordimmerimage");
                            getImages("ityre_rearviewmirrordimmerimage");
                          }}
                        >
                          {images.ityre_rearviewmirrordimmerimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearviewmirrordimmerimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearviewmirrordimmerimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearviewmirrordimmerimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearviewmirrordimmerimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_horn"
                    className="form-label btntextSize text-black"
                  >
                    Horn
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_horn"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.horn}
                      onChange={(e) => updateCarPart("horn", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Working but sound is not perfect">
                        Working but sound is not perfect
                      </option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_hornimage &&
                      images.ityre_hornimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_hornimage);
                            setImageId("ityre_hornimage");
                            getImages("ityre_hornimage");
                          }}
                        >
                          {images.ityre_hornimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_hornimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_hornimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_hornimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_hornimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_lightsleverswitch"
                    className="form-label btntextSize text-black"
                  >
                    Lights Lever / Switch (High / Low Beams , Indicators)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_lightsleverswitch"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.lightsleverswitch}
                      onChange={(e) =>
                        updateCarPart("lightsleverswitch", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Working with issues">
                        Working with issues
                      </option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_lightsleverswitchimage &&
                      images.ityre_lightsleverswitchimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_lightsleverswitchimage
                            );
                            setImageId("ityre_lightsleverswitchimage");
                            getImages("ityre_lightsleverswitchimage");
                          }}
                        >
                          {images.ityre_lightsleverswitchimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_lightsleverswitchimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_lightsleverswitchimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_lightsleverswitchimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_lightsleverswitchimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_wiperswasherlever"
                    className="form-label btntextSize text-black"
                  >
                    Wiper / Washer Lever (Washer,Speeds)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_wiperswasherlever"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.wiperswasherlever}
                      onChange={(e) =>
                        updateCarPart("wiperswasherlever", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_wiperswasherleverimage &&
                      images.ityre_wiperswasherleverimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_wiperswasherleverimage
                            );
                            setImageId("ityre_wiperswasherleverimage");
                            getImages("ityre_wiperswasherleverimage");
                          }}
                        >
                          {images.ityre_wiperswasherleverimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_wiperswasherleverimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_wiperswasherleverimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_wiperswasherleverimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_wiperswasherleverimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_windscreenwiper"
                    className="form-label btntextSize text-black"
                  >
                    Windscreen Wiper
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_windscreenwiper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.windscreenwiper}
                      onChange={(e) =>
                        updateCarPart("windscreenwiper", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Cleaning Properly">
                        Cleaning Properly
                      </option>
                      <option value="Not Cleaning Properly">
                        Not Cleaning Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_windscreenwiperimage &&
                      images.ityre_windscreenwiperimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_windscreenwiperimage
                            );
                            setImageId("ityre_windscreenwiperimage");
                            getImages("ityre_windscreenwiperimage");
                          }}
                        >
                          {images.ityre_windscreenwiperimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_windscreenwiperimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_windscreenwiperimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_windscreenwiperimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_windscreenwiperimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_steeringwheelcondition"
                    className="form-label btntextSize text-black"
                  >
                    Steering Wheel Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_steeringwheelcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.steeringwheelcondition}
                      onChange={(e) =>
                        updateCarPart("steeringwheelcondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Torn">Torn</option>
                      <option value="Scratched">Scratched</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_steeringwheelconditionimage &&
                      images.ityre_steeringwheelconditionimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_steeringwheelconditionimage
                            );
                            setImageId("ityre_steeringwheelconditionimage");
                            getImages("ityre_steeringwheelconditionimage");
                          }}
                        >
                          {images.ityre_steeringwheelconditionimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwheelconditionimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwheelconditionimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwheelconditionimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwheelconditionimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_steeringwheelbuttons"
                    className="form-label btntextSize text-black"
                  >
                    Steering Wheel Buttons
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_steeringwheelbuttons"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.steeringwheelbuttons}
                      onChange={(e) =>
                        updateCarPart("steeringwheelbuttons", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_steeringwheelbuttonsimage &&
                      images.ityre_steeringwheelbuttonsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_steeringwheelbuttonsimage
                            );
                            setImageId("ityre_steeringwheelbuttonsimage");
                            getImages("ityre_steeringwheelbuttonsimage");
                          }}
                        >
                          {images.ityre_steeringwheelbuttonsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwheelbuttonsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwheelbuttonsimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwheelbuttonsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwheelbuttonsimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_autolockbutton"
                    className="form-label btntextSize text-black"
                  >
                    Auto Lock Button
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_autolockbutton"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.autolockbutton}
                      onChange={(e) =>
                        updateCarPart("autolockbutton", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_autolockbuttonimage &&
                      images.ityre_autolockbuttonimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_autolockbuttonimage
                            );
                            setImageId("ityre_autolockbuttonimage");
                            getImages("ityre_autolockbuttonimage");
                          }}
                        >
                          {images.ityre_autolockbuttonimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_autolockbuttonimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_autolockbuttonimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_autolockbuttonimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_autolockbuttonimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_interiorlightings"
                    className="form-label btntextSize text-black"
                  >
                    Interior Lightings
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_interiorlightings"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.interiorlightings}
                      onChange={(e) =>
                        updateCarPart("interiorlightings", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_interiorlightingsimage &&
                      images.ityre_interiorlightingsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_interiorlightingsimage
                            );
                            setImageId("ityre_interiorlightingsimage");
                            getImages("ityre_interiorlightingsimage");
                          }}
                        >
                          {images.ityre_interiorlightingsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_interiorlightingsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_interiorlightingsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_interiorlightingsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_interiorlightingsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_dashcontrolsac"
                    className="form-label btntextSize text-black"
                  >
                    Dash Controls - A/C
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashcontrolsac"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashcontrolsac}
                      onChange={(e) =>
                        updateCarPart("dashcontrolsac", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashcontrolsacimage &&
                      images.ityre_dashcontrolsacimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashcontrolsacimage
                            );
                            setImageId("ityre_dashcontrolsacimage");
                            getImages("ityre_dashcontrolsacimage");
                          }}
                        >
                          {images.ityre_dashcontrolsacimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsacimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolsacimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsacimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolsacimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_dashcontrolsdefog"
                    className="form-label btntextSize text-black"
                  >
                    Dash Controls - De-Fog
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashcontrolsdefog"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashcontrolsdefog}
                      onChange={(e) =>
                        updateCarPart("dashcontrolsdefog", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashcontrolsdefogimage &&
                      images.ityre_dashcontrolsdefogimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashcontrolsdefogimage
                            );
                            setImageId("ityre_dashcontrolsdefogimage");
                            getImages("ityre_dashcontrolsdefogimage");
                          }}
                        >
                          {images.ityre_dashcontrolsdefogimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsdefogimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolsdefogimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsdefogimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolsdefogimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_dashcontrolshazzardlights"
                    className="form-label btntextSize text-black"
                  >
                    Dash Controls - Hazzard Lights
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashcontrolshazzardlights"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashcontrolshazzardlights}
                      onChange={(e) =>
                        updateCarPart(
                          "dashcontrolshazzardlights",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashcontrolshazzardlightsimage &&
                      images.ityre_dashcontrolshazzardlightsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashcontrolshazzardlightsimage
                            );
                            setImageId("ityre_dashcontrolshazzardlightsimage");
                            getImages("ityre_dashcontrolshazzardlightsimage");
                          }}
                        >
                          {images.ityre_dashcontrolshazzardlightsimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolshazzardlightsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashcontrolshazzardlightsimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolshazzardlightsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashcontrolshazzardlightsimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_dashcontrolsparkingbutton"
                    className="form-label btntextSize text-black"
                  >
                    Dash Controls - Parking Button
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashcontrolsparkingbutton"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashcontrolsparkingbutton}
                      onChange={(e) =>
                        updateCarPart(
                          "dashcontrolsparkingbutton",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashcontrolsparkingbuttonimage &&
                      images.ityre_dashcontrolsparkingbuttonimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashcontrolsparkingbuttonimage
                            );
                            setImageId("ityre_dashcontrolsparkingbuttonimage");
                            getImages("ityre_dashcontrolsparkingbuttonimage");
                          }}
                        >
                          {images.ityre_dashcontrolsparkingbuttonimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsparkingbuttonimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashcontrolsparkingbuttonimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolsparkingbuttonimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashcontrolsparkingbuttonimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_parking_handbrake"
                    className="form-label btntextSize text-black"
                  >
                    Parking / Hand Brake
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_parking_handbrake"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.parking_handbrake}
                      onChange={(e) =>
                        updateCarPart("parking_handbrake", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Abnormal Operation">
                        Abnormal Operation
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_parkingimage &&
                      images.ityre_parkingimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_parkingimage);
                            setImageId("ityre_parkingimage");
                            getImages("ityre_parkingimage");
                          }}
                        >
                          {images.ityre_parkingimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_parkingimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_parkingimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_parkingimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_parkingimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_dashcontrolothers"
                    className="form-label btntextSize text-black"
                  >
                    Dash Controls - Others
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashcontrolothers"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashcontrolothers}
                      onChange={(e) =>
                        updateCarPart("dashcontrolothers", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashcontrolothersimage &&
                      images.ityre_dashcontrolothersimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashcontrolothersimage
                            );
                            setImageId("ityre_dashcontrolothersimage");
                            getImages("ityre_dashcontrolothersimage");
                          }}
                        >
                          {images.ityre_dashcontrolothersimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolothersimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolothersimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashcontrolothersimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_dashcontrolothersimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_audio"
                    className="form-label btntextSize text-black"
                  >
                    Audio/Video
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_audio"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.audioVideo}
                      onChange={(e) =>
                        updateCarPart("audioVideo", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Working with issues">
                        Working with issues
                      </option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_audioimage &&
                      images.ityre_audioimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_audioimage);
                            setImageId("ityre_audioimage");
                            getImages("ityre_audioimage");
                          }}
                        >
                          {images.ityre_audioimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_audioimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_audioimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_audioimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_audioimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_rearviewcamera"
                    className="form-label btntextSize text-black"
                  >
                    Rear View Camera
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearviewcamera"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearViewCamera}
                      onChange={(e) =>
                        updateCarPart("rearViewCamera", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working Properly">
                        Not Working Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearviewcameraimage &&
                      images.ityre_rearviewcameraimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearviewcameraimage
                            );
                            setImageId("ityre_rearviewcameraimage");
                            getImages("ityre_rearviewcameraimage");
                          }}
                        >
                          {images.ityre_rearviewcameraimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearviewcameraimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearviewcameraimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearviewcameraimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearviewcameraimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_sunroofcontrolbutton"
                    className="form-label btntextSize text-black"
                  >
                    Sun Roof Control Button
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_sunroofcontrolbutton"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.sunRoofControl}
                      onChange={(e) =>
                        updateCarPart("sunRoofControl", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_sunroofcontrolbuttonimage &&
                      images.ityre_sunroofcontrolbuttonimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_sunroofcontrolbuttonimage
                            );
                            setImageId("ityre_sunroofcontrolbuttonimage");
                            getImages("ityre_sunroofcontrolbuttonimage");
                          }}
                        >
                          {images.ityre_sunroofcontrolbuttonimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_sunroofcontrolbuttonimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_sunroofcontrolbuttonimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_sunroofcontrolbuttonimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_sunroofcontrolbuttonimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_rightheadlight"
                    className="form-label btntextSize text-black"
                  >
                    Right Headlight (Working)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rightheadlight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightHeadlightWorking}
                      onChange={(e) =>
                        updateCarPart("rightHeadlightWorking", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rightheadlightimage &&
                      images.ityre_rightheadlightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rightheadlightimage
                            );
                            setImageId("ityre_rightheadlightimage");
                            getImages("ityre_rightheadlightimage");
                          }}
                        >
                          {images.ityre_rightheadlightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightheadlightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rightheadlightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rightheadlightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rightheadlightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_leftheadlight"
                    className="form-label btntextSize text-black"
                  >
                    Left Headlight (Working)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_leftheadlight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftHeadlightWorking}
                      onChange={(e) =>
                        updateCarPart("leftHeadlightWorking", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_leftheadlightimage &&
                      images.ityre_leftheadlightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_leftheadlightimage);
                            setImageId("ityre_leftheadlightimage");
                            getImages("ityre_leftheadlightimage");
                          }}
                        >
                          {images.ityre_leftheadlightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftheadlightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_leftheadlightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftheadlightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_leftheadlightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_foglight"
                    className="form-label btntextSize text-black"
                  >
                    Fog Lights (Working)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_foglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.fogLightsWorking}
                      onChange={(e) =>
                        updateCarPart("fogLightsWorking", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_foglightimage &&
                      images.ityre_foglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_foglightimage);
                            setImageId("ityre_foglightimage");
                            getImages("ityre_foglightimage");
                          }}
                        >
                          {images.ityre_foglightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_foglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_foglightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_foglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_foglightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_righttaillight"
                    className="form-label btntextSize text-black"
                  >
                    Right Taillight (Working)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_righttaillight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rightTaillightWorking}
                      onChange={(e) =>
                        updateCarPart("rightTaillightWorking", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_righttaillightimage &&
                      images.ityre_righttaillightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_righttaillightimage
                            );
                            setImageId("ityre_righttaillightimage");
                            getImages("ityre_righttaillightimage");
                          }}
                        >
                          {images.ityre_righttaillightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_righttaillightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_righttaillightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_righttaillightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_righttaillightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_lefttaillight"
                    className="form-label btntextSize text-black"
                  >
                    Left Taillight (Working)
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_lefttaillight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftTaillightWorking}
                      onChange={(e) =>
                        updateCarPart("leftTaillightWorking", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_lefttaillightimage &&
                      images.ityre_lefttaillightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_lefttaillightimage);
                            setImageId("ityre_lefttaillightimage");
                            getImages("ityre_lefttaillightimage");
                          }}
                        >
                          {images.ityre_lefttaillightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_lefttaillightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_lefttaillightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_lefttaillightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_lefttaillightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="gauges"
                    className="form-label btntextSize text-black"
                  >
                    Gauges
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_guages"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.gauges}
                      onChange={(e) => updateCarPart("gauges", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_guagesimage &&
                      images.ityre_guagesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_guagesimage);
                            setImageId("ityre_guagesimage");
                            getImages("ityre_guagesimage");
                          }}
                        >
                          {images.ityre_guagesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_guagesimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_guagesimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_guagesimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_guagesimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_computercheckup"
                    className="form-label btntextSize text-black"
                  >
                    Computer Check up / Malfunction Check
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_computercheckup"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.computerCheckup}
                      onChange={(e) =>
                        updateCarPart("computerCheckup", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Error">No Error</option>
                      <option value="Error">Error</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_computercheckupimage &&
                      images.ityre_computercheckupimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_computercheckupimage
                            );
                            setImageId("ityre_computercheckupimage");
                            getImages("ityre_computercheckupimage");
                          }}
                        >
                          {images.ityre_computercheckupimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_computercheckupimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_computercheckupimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_computercheckupimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_computercheckupimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_steeringwarninglight"
                    className="form-label btntextSize text-black"
                  >
                    Power Steering Warning Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_steeringwarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.poerSteeringWarningLight}
                      onChange={(e) =>
                        updateCarPart(
                          "poerSteeringWarningLight",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_steeringwarninglightimage &&
                      images.ityre_steeringwarninglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_steeringwarninglightimage
                            );
                            setImageId("ityre_steeringwarninglightimage");
                            getImages("ityre_steeringwarninglightimage");
                          }}
                        >
                          {images.ityre_steeringwarninglightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwarninglightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_steeringwarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_steeringwarninglightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_keyfobbatterylowlight"
                    className="form-label btntextSize text-black"
                  >
                    Key Fob Battery Low Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_keyfobbatterylowlight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.keyFobLowLight}
                      onChange={(e) =>
                        updateCarPart("keyFobLowLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_keyfobbatterylowlightimage &&
                      images.ityre_keyfobbatterylowlightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_keyfobbatterylowlightimage
                            );
                            setImageId("ityre_keyfobbatterylowlightimage");
                            getImages("ityre_keyfobbatterylowlightimage");
                          }}
                        >
                          {images.ityre_keyfobbatterylowlightimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_keyfobbatterylowlightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_keyfobbatterylowlightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_keyfobbatterylowlightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_keyfobbatterylowlightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_batterywarninglight"
                    className="form-label btntextSize text-black"
                  >
                    Battery Warning Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_batterywarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.batteryWarningLight}
                      onChange={(e) =>
                        updateCarPart("batteryWarningLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_batterywarninglightimage &&
                      images.ityre_batterywarninglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_batterywarninglightimage
                            );
                            setImageId("ityre_batterywarninglightimage");
                            getImages("ityre_batterywarninglightimage");
                          }}
                        >
                          {images.ityre_batterywarninglightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_batterywarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_batterywarninglightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_batterywarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_batterywarninglightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_airbagwarninglight"
                    className="form-label btntextSize text-black"
                  >
                    Air Bag Warning Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_airbagwarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.airBagWarningLight}
                      onChange={(e) =>
                        updateCarPart("airBagWarningLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_airbagwarninglightimage &&
                      images.ityre_airbagwarninglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_airbagwarninglightimage
                            );
                            setImageId("ityre_airbagwarninglightimage");
                            getImages("ityre_airbagwarninglightimage");
                          }}
                        >
                          {images.ityre_airbagwarninglightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_airbagwarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_airbagwarninglightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_airbagwarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_airbagwarninglightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="tempwarninglight"
                    className="form-label btntextSize text-black"
                  >
                    Temprature Warning Light / Gauge
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_tempraturewarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.tempratureWarningLight}
                      onChange={(e) =>
                        updateCarPart("tempratureWarningLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_tempraturewarninglightimage &&
                      images.ityre_tempraturewarninglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_tempraturewarninglightimage
                            );
                            setImageId("ityre_tempraturewarninglightimage");
                            getImages("ityre_tempraturewarninglightimage");
                          }}
                        >
                          {images.ityre_tempraturewarninglightimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_tempraturewarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_tempraturewarninglightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_tempraturewarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_tempraturewarninglightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ityre_abswarninglight"
                    className="form-label btntextSize text-black"
                  >
                    ABS Warning Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_abswarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.absWarningLight}
                      onChange={(e) =>
                        updateCarPart("absWarningLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_abswarninglightimage &&
                      images.ityre_abswarninglightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_abswarninglightimage
                            );
                            setImageId("ityre_abswarninglightimage");
                            getImages("ityre_abswarninglightimage");
                          }}
                        >
                          {images.ityre_abswarninglightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_abswarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_abswarninglightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_abswarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_abswarninglightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="oilpressurewarning"
                    className="form-label btntextSize text-black"
                  >
                    Oil Pressure Low Warning Light
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_oilpressurelowwarninglight"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.oilPressureWarningLight}
                      onChange={(e) =>
                        updateCarPart("oilPressureWarningLight", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Not Present">Not Present</option>
                      <option value="Present">Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_oilpressurelowwarninglightimage &&
                      images.ityre_oilpressurelowwarninglightimage.length >
                        0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_oilpressurelowwarninglightimage
                            );
                            setImageId("ityre_oilpressurelowwarninglightimage");
                            getImages("ityre_oilpressurelowwarninglightimage");
                          }}
                        >
                          {images.ityre_oilpressurelowwarninglightimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_oilpressurelowwarninglightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_oilpressurelowwarninglightimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_oilpressurelowwarninglightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_oilpressurelowwarninglightimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="blower"
                    className="form-label btntextSize text-black"
                  >
                    Blower
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_blower"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.blower}
                      onChange={(e) => updateCarPart("blower", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Excellent Air Throw">
                        Excellent Air Throw
                      </option>
                      <option value="Inadequate Air Throw or Not Working">
                        Inadequate Air Throw or Not Working
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_blowerimage &&
                      images.ityre_blowerimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_blowerimage);
                            setImageId("ityre_blowerimage");
                            getImages("ityre_blowerimage");
                          }}
                        >
                          {images.ityre_blowerimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_blowerimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_blowerimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_blowerimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_blowerimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="heating"
                    className="form-label btntextSize text-black"
                  >
                    Heating
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_heating"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.heating}
                      onChange={(e) => updateCarPart("heating", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Moderate or Not Working">
                        Moderate or Not Working
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_heatingimage &&
                      images.ityre_heatingimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_heatingimage);
                            setImageId("ityre_heatingimage");
                            getImages("ityre_heatingimage");
                          }}
                        >
                          {images.ityre_heatingimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_heatingimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_heatingimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_heatingimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_heatingimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="cooling"
                    className="form-label btntextSize text-black"
                  >
                    Cooling
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_cooling"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.cooling}
                      onChange={(e) => updateCarPart("cooling", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Excellent">Excellent</option>
                      <option value="Moderate or Not Working">
                        Moderate or Not Working
                      </option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_coolingimage &&
                      images.ityre_coolingimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_coolingimage);
                            setImageId("ityre_coolingimage");
                            getImages("ityre_coolingimage");
                          }}
                        >
                          {images.ityre_coolingimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_coolingimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_coolingimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_coolingimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_coolingimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rearrightwindowlever"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Power Window/Manual Lever
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearrightpowerwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearRightPowerManualLever}
                      onChange={(e) =>
                        updateCarPart(
                          "rearRightPowerManualLever",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working Properly">Working Properly</option>
                      <option value="Not Working Properly">
                        Not Working Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearrightpowerwindowimage &&
                      images.ityre_rearrightpowerwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearrightpowerwindowimage
                            );
                            setImageId("ityre_rearrightpowerwindowimage");
                            getImages("ityre_rearrightpowerwindowimage");
                          }}
                        >
                          {images.ityre_rearrightpowerwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearrightpowerwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearrightpowerwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearrightpowerwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearrightpowerwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rearseatposhish"
                    className="form-label btntextSize text-black"
                  >
                    Rear Seat Poshish
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearseatposhish"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearSeatPoshish}
                      onChange={(e) =>
                        updateCarPart("rearSeatPoshish", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Not Checked due to Market Cover">
                        Not Checked due to Market Cover
                      </option>
                      <option value="Dirty">Dirty</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearseatposhishimage &&
                      images.ityre_rearseatposhishimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearseatposhishimage
                            );
                            setImageId("ityre_rearseatposhishimage");
                            getImages("ityre_rearseatposhishimage");
                          }}
                        >
                          {images.ityre_rearseatposhishimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearseatposhishimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearseatposhishimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearseatposhishimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearseatposhishimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rearrightdoorwindow"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Door Window
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearrightdoorwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearRightDoorWindow}
                      onChange={(e) =>
                        updateCarPart("rearRightDoorWindow", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearrightdoorwindowimage &&
                      images.ityre_rearrightdoorwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearrightdoorwindowimage
                            );
                            setImageId("ityre_rearrightdoorwindowimage");
                            getImages("ityre_rearrightdoorwindowimage");
                          }}
                        >
                          {images.ityre_rearrightdoorwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearrightdoorwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearrightdoorwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearrightdoorwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearrightdoorwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rearleftwindowlever"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Power Window/Manual Lever
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearleftpowerwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearLeftPowerManualLever}
                      onChange={(e) =>
                        updateCarPart(
                          "rearLeftPowerManualLever",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working Properly">Working Properly</option>
                      <option value="Not Working Properly">
                        Not Working Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearleftpowerwindowimage &&
                      images.ityre_rearleftpowerwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearleftpowerwindowimage
                            );
                            setImageId("ityre_rearleftpowerwindowimage");
                            getImages("ityre_rearleftpowerwindowimage");
                          }}
                        >
                          {images.ityre_rearleftpowerwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearleftpowerwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearleftpowerwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearleftpowerwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearleftpowerwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="rearleftdoorwindow"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Door Window
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearleftdoorwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearLeftDoorWindow}
                      onChange={(e) =>
                        updateCarPart("rearLeftDoorWindow", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearleftdoorwindowimage &&
                      images.ityre_rearleftdoorwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_rearleftdoorwindowimage
                            );
                            setImageId("ityre_rearleftdoorwindowimage");
                            getImages("ityre_rearleftdoorwindowimage");
                          }}
                        >
                          {images.ityre_rearleftdoorwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearleftdoorwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearleftdoorwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearleftdoorwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_rearleftdoorwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Rear Seat Belts
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_rearseatbelts"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.rearSeatBelts}
                      onChange={(e) =>
                        updateCarPart("rearSeatBelts", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_rearseatbeltsimage &&
                      images.ityre_rearseatbeltsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_rearseatbeltsimage);
                            setImageId("ityre_rearseatbeltsimage");
                            getImages("ityre_rearseatbeltsimage");
                          }}
                        >
                          {images.ityre_rearseatbeltsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearseatbeltsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearseatbeltsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_rearseatbeltsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_rearseatbeltsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Left Seat Adjuster Recliner
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_leftseatadjusterrecliner"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftSeatAdjusterRecliner}
                      onChange={(e) =>
                        updateCarPart(
                          "leftSeatAdjusterRecliner",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working or Broken">
                        Not Working or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_leftseatadjusterreclinerimage &&
                      images.ityre_leftseatadjusterreclinerimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_leftseatadjusterreclinerimage
                            );
                            setImageId("ityre_leftseatadjusterreclinerimage");
                            getImages("ityre_leftseatadjusterreclinerimage");
                          }}
                        >
                          {images.ityre_leftseatadjusterreclinerimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatadjusterreclinerimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftseatadjusterreclinerimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatadjusterreclinerimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftseatadjusterreclinerimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Left Seat Adjuster Lear Track
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_leftseatadjusterleartrack"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftSeatAdjusterLearTrack}
                      onChange={(e) =>
                        updateCarPart(
                          "leftSeatAdjusterLearTrack",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Moving or Broken">
                        Not Moving or Broken
                      </option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_leftseatadjusterleartrackimage &&
                      images.ityre_leftseatadjusterleartrackimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_leftseatadjusterleartrackimage
                            );
                            setImageId("ityre_leftseatadjusterleartrackimage");
                            getImages("ityre_leftseatadjusterleartrackimage");
                          }}
                        >
                          {images.ityre_leftseatadjusterleartrackimage.length}{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatadjusterleartrackimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftseatadjusterleartrackimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatadjusterleartrackimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftseatadjusterleartrackimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="leftfrontseatelectricfunction"
                    className="form-label btntextSize text-black"
                  >
                    Left Front Seat Electric Function
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_leftfrontseatelectricfunction"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftFrontSeatElectricFunction}
                      onChange={(e) =>
                        updateCarPart(
                          "leftFrontSeatElectricFunction",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_leftfrontseatelectricfunctionimage &&
                      images.ityre_leftfrontseatelectricfunctionimage.length >
                        0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_leftfrontseatelectricfunctionimage
                            );
                            setImageId(
                              "ityre_leftfrontseatelectricfunctionimage"
                            );
                            getImages(
                              "ityre_leftfrontseatelectricfunctionimage"
                            );
                          }}
                        >
                          {
                            images.ityre_leftfrontseatelectricfunctionimage
                              .length
                          }{" "}
                          photos <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftfrontseatelectricfunctionimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftfrontseatelectricfunctionimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftfrontseatelectricfunctionimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_leftfrontseatelectricfunctionimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="frontleftwindowlever"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Power Window/Manual Lever
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontleftpowerwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontLeftPowerManualLever}
                      onChange={(e) =>
                        updateCarPart(
                          "frontLeftPowerManualLever",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working Properly">Working Properly</option>
                      <option value="Not Working Properly">
                        Not Working Properly
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontleftpowerwindowimage &&
                      images.ityre_frontleftpowerwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontleftpowerwindowimage
                            );
                            setImageId("ityre_frontleftpowerwindowimage");
                            getImages("ityre_frontleftpowerwindowimage");
                          }}
                        >
                          {images.ityre_frontleftpowerwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftpowerwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftpowerwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftpowerwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftpowerwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Seat Poshish
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontleftseatposhish"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontLeftSeatPoshish}
                      onChange={(e) =>
                        updateCarPart("frontLeftSeatPoshish", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Not Checked due to Market Cover">
                        Not Checked due to Market Cover
                      </option>
                      <option value="Dirty">Dirty</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontleftseatposhishimage &&
                      images.ityre_frontleftseatposhishimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontleftseatposhishimage
                            );
                            setImageId("ityre_frontleftseatposhishimage");
                            getImages("ityre_frontleftseatposhishimage");
                          }}
                        >
                          {images.ityre_frontleftseatposhishimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftseatposhishimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftseatposhishimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftseatposhishimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftseatposhishimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="frontleftdoorwindow"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Door Window
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_frontleftdoorwindow"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.frontLeftDoorWindow}
                      onChange={(e) =>
                        updateCarPart("frontLeftDoorWindow", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_frontleftdoorwindowimage &&
                      images.ityre_frontleftdoorwindowimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_frontleftdoorwindowimage
                            );
                            setImageId("ityre_frontleftdoorwindowimage");
                            getImages("ityre_frontleftdoorwindowimage");
                          }}
                        >
                          {images.ityre_frontleftdoorwindowimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftdoorwindowimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftdoorwindowimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_frontleftdoorwindowimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_frontleftdoorwindowimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Left Seat Belt
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_leftseatbelt"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.leftSeatBelt}
                      onChange={(e) =>
                        updateCarPart("leftSeatBelt", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Working">Working</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_leftseatbeltimage &&
                      images.ityre_leftseatbeltimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_leftseatbeltimage);
                            setImageId("ityre_leftseatbeltimage");
                            getImages("ityre_leftseatbeltimage");
                          }}
                        >
                          {images.ityre_leftseatbeltimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatbeltimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_leftseatbeltimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_leftseatbeltimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_leftseatbeltimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Glove Box
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_glovebox"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.gloveBox}
                      onChange={(e) =>
                        updateCarPart("gloveBox", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Not Working or Broken">
                        Not Working or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_gloveboximage &&
                      images.ityre_gloveboximage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_gloveboximage);
                            setImageId("ityre_gloveboximage");
                            getImages("ityre_gloveboximage");
                          }}
                        >
                          {images.ityre_gloveboximage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_gloveboximage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_gloveboximage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_gloveboximage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_gloveboximage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Dashboard Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_dashboardcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.dashboardCondition}
                      onChange={(e) =>
                        updateCarPart("dashboardCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Minor Spots">Minor Spots</option>
                      <option value="Cracks">Cracks</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_dashboardconditionimage &&
                      images.ityre_dashboardconditionimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ityre_dashboardconditionimage
                            );
                            setImageId("ityre_dashboardconditionimage");
                            getImages("ityre_dashboardconditionimage");
                          }}
                        >
                          {images.ityre_dashboardconditionimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashboardconditionimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashboardconditionimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_dashboardconditionimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "ityre_dashboardconditionimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/*               
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label for="field17" className="form-label btntextSize text-black">
                    Left Seat Adjuster Recliner 
                  </label>
                  <div className="input-group">
                    <select
                      name="ii_leftseatrecliner"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      //   value={categoryName}
                      //   onChange={(e) => setCategoryName(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>
                </div>
              </div> */}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Floor Mat
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_floormat"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.floorMat}
                      onChange={(e) =>
                        updateCarPart("floorMat", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Dirty">Dirty</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_floormatimage &&
                      images.ityre_floormatimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_floormatimage);
                            setImageId("ityre_floormatimage");
                            getImages("ityre_floormatimage");
                          }}
                        >
                          {images.ityre_floormatimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_floormatimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_floormatimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_floormatimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_floormatimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="field17"
                    className="form-label btntextSize text-black"
                  >
                    Roof Poshish
                  </label>
                  <div className="input-group">
                    <select
                      name="ityre_roofposhish"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step6.roofPoshish}
                      onChange={(e) =>
                        updateCarPart("roofPoshish", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Dirty">Dirty</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ityre_roofposhishimage &&
                      images.ityre_roofposhishimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ityre_roofposhishimage);
                            setImageId("ityre_roofposhishimage");
                            getImages("ityre_roofposhishimage");
                          }}
                        >
                          {images.ityre_roofposhishimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_roofposhishimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_roofposhishimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ityre_roofposhishimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ityre_roofposhishimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="ityre_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="ityre_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center d-flex justify-content-center align-items-center gap-2 pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    className=" px-5 color-white"
                    disabled={
                      pending === true ||
                      isSubmitting === true ||
                      isSubmittingFields === true
                        ? true
                        : false
                    }
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step5;
