import React, { useEffect, useState } from "react";

import "../Styles/style.css";
import Step9 from "./steps/step9";
import { AuthContext } from "../../context/AuthContext";

const InspectorForm = () => {
  const { user } = React.useContext(AuthContext);

  const [step2, seStep2] = useState({
    rightMirror: null,
    rightAPillar: null,
    leftHeadlightCondition: null,
    rightHeadlightCondition: null,
    radiatorCoreSupport: null,
    leftFrontRail: null,
    rightFrontRail: null,
    cowlPanelFirewall: null,
    frontSubFrame: null,
    leftStrutPowerApon: null,
    rightStrutPowerApon: null,
    frontWindShield: null,
    terminalsCondition: null,
    voltage: null,
    hoses: null,
    bonnetButton: null,
    trunkButton: null,
    fuelCapButton: null,
    engineOilLevel: null,
    wiringHarness: null,
    beltsFan: null,
    beltsAc: null,
    radiator: null,
    frontRightBPillar: null,
    rearRightCPillar: null,
    rearRightDPillar: null,
    bonnet: null,
    frontBumper: null,
    frontRightFender: null,
    frontRightDoor: null,
    rearRightDoor: null,
    rightFootBand: null,
    rearRightFender: null,
    rightTailLightCondition: null,
  });

  return (
    <>
      <div className="container mt-3">
        <div className="text-center post-intro pb-4">
          <h3 className=" fw-700">
            {" "}
            <span className="text-danger"> Short </span> Inspection Form
          </h3>
        </div>

        <div className="inspectorForm_root mt-3">
          <Step9
            step2={step2}
            setStep2={seStep2}
            user={user?.id}
            userData={user}
          />
        </div>
      </div>
    </>
  );
};

export default InspectorForm;
