import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import InspectionSuccess from "../../modals/inspection-success";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../modals/loading-modal";
import ImageIcon from "@mui/icons-material/Image";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import carSkeleton from "../../../images/car-skeleton.png";
import { Form } from "react-bootstrap";
import baseUrl from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step7 = ({ onSuccess, previouse, user, userData, step3, setStep3 }) => {
  const token = localStorage.getItem("token");

  const [defects, setDefects] = useState({});
  const [defectImage, setDefectImage] = useState(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);

  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [stepImages, setStepImages] = useState(null);

  const [defectId, setDefectId] = useState(null);

  const [roofDefectTypes, setRoofDefectTypes] = useState([]);
  const [leftFootBandDefectTypes, setLeftFootBandDefectTypes] = useState([]);
  const [rearBumperDefectTypes, setRearBumperDefectTypes] = useState([]);
  const [frontLeftFenderDefectTypes, setFrontLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftFenderDefectTypes, setRearLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftDoorDefectTypes, setRearLeftDoorDefectTypes] = useState([]);
  const [frontLeftDoorDefectTypes, setFrontLeftDoorDefectTypes] = useState([]);
  const [trunkDefectTypes, setTrunkDefectTypes] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  /// defect buttons

  const [trunkDefectBtn, setTrunkDefectBtn] = useState(false);
  const [rearBumperDefectBtn, setRearBumperDefectBtn] = useState(false);
  const [rearLeftFenderDefectBtn, setRearLeftFenderDefectBtn] = useState(false);
  const [leftFootBandDefectBtn, setLeftFootBandDefectBtn] = useState(false);
  const [rearLeftDoorDefectBtn, setRearLeftDoorDefectBtn] = useState(false);
  const [frontLeftDoorDefectBtn, setFrontLeftDoorDefectBtn] = useState(false);
  const [frontLeftFenderDefectBtn, setFrontLeftFenderDefectBtn] =
    useState(false);
  const [roofDefectBtn, setRoofDefectBtn] = useState(false);

  // example start

  const [prevImg, setPrevImg] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);

  const [defectedAreaPic, setDefectedAreaPic] = useState("");
  const [partId, setPartId] = useState("");
  const [openList, setOpenList] = useState(false);
  const [openCarSkeleton, setOpenCarSkeleton] = useState(false);
  const [allDefects, setAllDefects] = useState([]);

  const handleImageGallery = () => {
    setOpenImageGallery(true);
  };
  // example end

  const formRef = useRef(null);
  const btnRef = useRef(null);
  const history = useHistory();
  const [successIsOpen, setSuccessIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);
  const [isSycc, setIsSync] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");
  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 1500);
  }, [syncable]);

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const filterDefectsByField = (allDefects) => {
    const filteredDefects = allDefects.filter(
      (defect) => defect.initial_token === StepToken
    );

    const filteredDefectsByStep = filteredDefects.filter(
      (defect) => defect.inspectionstep_id === 3
    );

    setAllDefects(filteredDefectsByStep);

    try {
      const trunkDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "trunk_defect"
      );
      const rearBumperDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "rearbumper_defect"
      );

      const rearLeftFenderDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "rearleftfender_defect"
      );

      const leftFootBoardDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "leftfootboard_defect"
      );

      const rearLeftDoorDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "rearleftdoor_defect"
      );

      const frontLeftDoorDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "frontleftdoor_defect"
      );

      const frontLeftFenderDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "frontleftfender_defect"
      );

      const roofDefects = filteredDefects?.filter(
        (defect) => defect.defect_place === "roof_defect"
      );

      if (trunkDefects.length > 0) {
        setTrunkDefectTypes(trunkDefects);
      } else {
        setTrunkDefectTypes([]);
      }

      if (rearBumperDefects.length > 0) {
        setRearBumperDefectTypes(rearBumperDefects);
      } else {
        setRearBumperDefectTypes([]);
      }

      if (rearLeftFenderDefects.length > 0) {
        setRearLeftFenderDefectTypes(rearLeftFenderDefects);
      } else {
        setRearLeftFenderDefectTypes([]);
      }

      if (leftFootBoardDefects.length > 0) {
        setLeftFootBandDefectTypes(leftFootBoardDefects);
      } else {
        setLeftFootBandDefectTypes([]);
      }

      if (rearLeftDoorDefects.length > 0) {
        setRearLeftDoorDefectTypes(rearLeftDoorDefects);
      } else {
        setRearLeftDoorDefectTypes([]);
      }

      if (frontLeftDoorDefects.length > 0) {
        setFrontLeftDoorDefectTypes(frontLeftDoorDefects);
      } else {
        setFrontLeftDoorDefectTypes([]);
      }

      if (frontLeftFenderDefects.length > 0) {
        setFrontLeftFenderDefectTypes(frontLeftFenderDefects);
      } else {
        setFrontLeftFenderDefectTypes([]);
      }

      if (roofDefects.length > 0) {
        setRoofDefectTypes(roofDefects);
      } else {
        setRoofDefectTypes([]);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDefectImageChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => {
        const reader = new FileReader();
        setDefectImage(file);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(file.slice()); // Store the Blob in prevImg
            setPrevImg(reader.result); // You can still set prevImg for preview
          };
          reader.onerror = (error) => {
            console.error("Error reading file:", error);
            resolve(null); // Resolve with null on error
          };
          reader.readAsDataURL(file);
        });
      });
    }
  };

  const getAllDefectsFromDB = async () => {
    try {
      const db = await openDB("defects-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("defects")) {
            const objectStore = db.createObjectStore("defects", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });
      //console.log("Database opened successfully:", db);

      const transaction = db.transaction("defects", "readonly");
      //console.log("Transaction started:", transaction);

      const objectStore = transaction.objectStore("defects");
      //console.log("Object store retrieved:", objectStore);

      const stored = await objectStore.getAll();

      //console.log("stored defects", stored);

      transaction.oncomplete = function () {
        //console.log("Transaction completed");
        // Do something with the retrieved data, like updating state or displaying it in the UI
      };

      if (stored) {
        filterDefectsByField(stored);
      }
    } catch (error) {
      console.error("Error retrieving defects from IndexedDB:", error);
    }
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 3);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      // getAllDefects();
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          // const formData = new FormData();

          // // Append common form data fields
          // formData.append("iinitial_token", StepToken);
          // formData.append("inspectionstep_id", 2);

          // // Append each image to formData
          // formData.append(`file[]`, image.image);
          // formData.append(`image_name`, image.name); // Assuming name is an array

          // //console.log("FormData:", formData.get("image_name"));

          // const response = await axios.post(uploadEndpoint, formData, {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          //console.log("API Response:", response.data);

          const s3Key = `public/inspection/${StepToken}/${image?.name}/${image?.image?.name}`;

          const params = {
            Bucket: "famewheels",
            Key: s3Key,
            Body: image.image,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: image.image.type,
          };

          const response = await s3.upload(params).promise();

          const url = response.Key;

          const ImageName = url.substring(url.lastIndexOf("/") + 1);

          const formData = new FormData();
          formData.append("image_name", image?.name);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 3);
          formData.append(`file`, ImageName);

          const responseApi = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          await handleDeleteImage(image.id, image.id);
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmitting(false);
        }
      }
    }

    //   stepImages.forEach(async (image) => {
    // try {
    // setIsSubmitting(true)

    // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
    //       const formData = new FormData();

    //       // Append common form data fields
    //       formData.append("iinitial_token", StepToken);
    //       formData.append("inspectionstep_id", 3);

    //       // Append each image to formData
    //         formData.append(`file[]`, image.image);
    //         formData.append(`image_name`, image.name); // Assuming name is an array

    //         //console.log("FormData:", formData.get("image_name"));

    //         const response = await axios.post(uploadEndpoint, formData, {
    //           headers: {
    //           Authorization: `Bearer ${token}`,
    //           "Content-Type": "multipart/form-data",
    //         },
    //       });

    //       //console.log("API Response:", response.data);

    //     handleDeleteImage(image.id,image.id)

    //     } catch (error) {
    //       //console.log(error)
    //     }finally{
    // setIsSubmitting(false)
    //     }
    //   });
  };

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = filteredImagesData.filter(
        (images) => images.inspection_step === 3
      );

      setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_third`);
      if (formData) {
        const newData = JSON.parse(formData);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const deleteDefect = async () => {
    try {
      setPending(true);
      setPendingData("Deleting Defect");

      const db = await openDB("defects-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("defects", "readwrite");
      const store = tx.objectStore("defects");

      // Delete the image with the specified id
      await store.delete(defectId?.id);

      await getAllDefectsFromDB();

      setOpenDeleteModal(false);
    } catch (error) {
      //console.log(error);
    } finally {
      setPending(false);
      setPendingData("");
    }
  };

  const handleDeleteDefect = (type) => {
    setOpenDeleteModal(true);
    setDefectId(type);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllDefectsFromDB();
        await getLocalImages();
      } catch (error) {
        console.error("Error fetching defects:", error);
      }
    };

    fetchData();
  }, []);

  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    // Open the IndexedDB database
    const db = await openDB("my-database", 1, {
      upgrade(db) {
        // Check if the object store exists before creating it
        if (!db.objectStoreNames.contains("images")) {
          // Create an object store for storing images
          db.createObjectStore("images", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });

    // Iterate over each file
    for (const file of files) {
      //console.log("Original file:", file);

      const options = {
        maxSizeMB: 0.06,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      //console.log("Compressed file:", compressedFile);

      const compressedImageFile = new File([compressedFile], file.name, {
        type: file.type,
      });

      const imageData = {
        name: fieldName,
        image: compressedImageFile,
        token: StepToken,
        inspection_step: 3,
      };

      try {
        // Store the image data in IndexedDB
        const tx = db.transaction("images", "readwrite");
        const store = tx.objectStore("images");
        await store.add(imageData);
        await tx.done;
        //console.log("Image uploaded to IndexedDB successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Retrieve the stored images from IndexedDB
    await getLocalImages();
  };
  const defectsTypes = async () => {
    try {
      // Update defects state and perform IndexedDB operations
      setDefects((prevDefects) => {
        const newDefects = {
          ...prevDefects,
          [partId]: [
            ...(prevDefects[partId] || []),
            {
              defect_place: partId,
              defect_type: defectedAreaPic,
              initial_token: StepToken,
              inspectionstep_id: 3,
              file: defectImage || null, // Include file field only when there's a valid image
            },
          ],
        };

        // Open connection to IndexedDB
        openDB("defects-database", 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains("defects")) {
              const objectStore = db.createObjectStore("defects", {
                keyPath: "id",
                autoIncrement: true,
              });
            }
          },
        })
          .then(async (db) => {
            // Store defects for each dynamic key
            const transaction = db.transaction(["defects"], "readwrite");
            const objectStore = transaction.objectStore("defects");
            const defectsArray = newDefects[partId];
            for (const defect of defectsArray) {
              const addRequest = objectStore.add(defect);
              addRequest.onsuccess = function (event) {
                //console.log("Defect added to IndexedDB");
              };
              addRequest.onerror = function (event) {
                console.error(
                  "Error adding defect to IndexedDB: " + event.target.errorCode
                );
              };
            }
            await transaction.done;

            // Call getAllDefectsFromDB after adding all defects
            setDefects({});
            await getAllDefectsFromDB();

            return newDefects;
          })
          .catch((error) => {
            console.error("Error storing defects in IndexedDB:", error);
            setDefects({});
            return prevDefects; // Return previous state if there's an error
          });

        return newDefects;
      });
    } catch (error) {
      console.error("Error updating defects state:", error);
    }
    setDefectImage(null);
    setDefectedAreaPic("");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "3";
            obj.token = StepToken;
          }
        });

        //console.log("inspectionDraftArray === ?>>> ",inspectionDraftArray);

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_third`, serializedFormData);
        localStorage.setItem(`stepId`, 3);

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 2");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  const defectsArr = [
    {
      value: "P-0.55",
      name: "P",
    },
    {
      value: "A1-0.005",
      name: "A1",
    },
    {
      value: "A2-0.0314786",
      name: "A2",
    },
    {
      value: "A3-0.056",
      name: "A3",
    },
    {
      value: "B1-0.005",
      name: "B1",
    },
    {
      value: "B2-0.0314786",
      name: "B2",
    },
    {
      value: "B3-0.056",
      name: "B3",
    },
    {
      value: "C1-0.005",
      name: "C1",
    },
    {
      value: "C2-0.022508",
      name: "C2",
    },
    {
      value: "D1-0.005",
      name: "D1",
    },
    {
      value: "D2-0.0314786",
      name: "D2",
    },
    {
      value: "D3-0.056",
      name: "D3",
    },
    {
      value: "E1-0",
      name: "E1",
    },
    {
      value: "E2-0",
      name: "E2",
    },
    {
      value: "E3-0",
      name: "E3",
    },
    {
      value: "S1-0.005",
      name: "S1",
    },
    {
      value: "S2-0.022508",
      name: "S2",
    },
    {
      value: "W1-0",
      name: "W1",
    },
    {
      value: "W2-0",
      name: "W2",
    },
    {
      value: "W3-0",
      name: "W3",
    },
    {
      value: "Y1-0.005",
      name: "Y1",
    },
    {
      value: "Y2-0.022508",
      name: "Y2",
    },
    {
      value: "Y3-0.03",
      name: "Y3",
    },
    {
      value: "X-0.03",
      name: "X",
    },
    {
      value: "XX-0",
      name: "XX",
    },
    {
      value: "H-0.03004",
      name: "H",
    },
  ];

  const handeDefectsSave = async () => {
    if (allDefects?.length > 0) {
      for (const defect of allDefects) {
        try {
          setIsSubmitting(true);

          const s3Key = `public/defect/${StepToken}/${defect.defect_place}/${defect.defect_type}/${defect.file.name}`;

          const params = {
            Bucket: "famewheels",
            Key: s3Key,
            Body: defect.file,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: defect.file.type,
          };

          const responseImage = await s3.upload(params).promise();

          const formData = new FormData();

          formData.append("defect_place", defect.defect_place);
          formData.append("defect_type", defect.defect_type);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 2);
          if (responseImage?.Key) {
            formData.append("file", responseImage?.Key);
          }

          const response = await axios.post(`${baseUrl}/defects`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          // if (response?.data) {
          await deleteDefectById(defect.id);
          setSuccessOpen(true);
          // setSuccess(response?.data);
          // }
        } catch (error) {
          setError(error.response.data?.error);
          //console.log(error);
          setErrOpen(true);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const deleteDefectById = async (defectId) => {
    try {
      const db = await openDB("defects-database", 1);
      const tx = db.transaction("defects", "readwrite");
      const store = tx.objectStore("defects");
      await store.delete(defectId);
      await tx.done; // Using complete to ensure the transaction is fully committed
      //console.log('Defect deleted from IndexedDB');
    } catch (error) {
      console.error("Error deleting defect from IndexedDB:", error);
    }
  };

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const CreateUser = async () => {
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("userId", user?.id);
        params.data.append("iinitial_token", StepToken);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse?.iinitial_id
        );
        params.data.append("inspectionstep_id", 3);

        Object.entries(images).forEach(([fieldName, file]) => {
          params.data.append(fieldName, file);
        });

        try {
          const response = await axios.post(
            `${baseUrl}/saveacinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response?.data) {
            setSuccessOpen(true);
            setSuccess("Step 3 Completed");
            setTimeout(() => {
              onSuccess();
            }, 500);
          }
          // localStorage.removeItem(`${StepToken}_third`)
          //console.log("====================================");
          //console.log(response);
          if (response?.status !== 200) {
            setIsSubmitting(false);
          }
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 3 Submit Failed!`);
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setIsSubmitting(true);
        setIsSubmittingFields(true);
        setPending(true);
        setPendingData("Completing Step 3");
        await CreateUser();
      } catch (e) {
        console.error(e);
        // Handle error if needed
      } finally {
        setPending(false);
        setIsSubmitting(false);
        setIsSubmittingFields(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      await postImages();
      await handeDefectsSave();
      await handleSaveFields();
    } catch (error) {
      //console.log(error)
    } finally {
      // setIsSubmittingFields(false)
      // setIsSubmitting(false)
    }
  };

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 2000);
    }
  }, [isSycc]);

  useEffect(() => {
    if (step3.trunk === "Not Ok") {
      setTrunkDefectBtn(true);
    }

    if (step3.rearBumper === "Not Ok") {
      setRearBumperDefectBtn(true);
    }

    if (step3.rearLeftFender === "Not Ok") {
      setRearLeftFenderDefectBtn(true);
    }

    if (step3.leftFootBand === "Not Ok") {
      setLeftFootBandDefectBtn(true);
    }

    if (step3.rearLeftDoor === "Not Ok") {
      setRearLeftDoorDefectBtn(true);
    }

    if (step3.frontLeftDoor === "Not Ok") {
      setFrontLeftDoorDefectBtn(true);
    }

    if (step3.frontLeftFender === "Not Ok") {
      setFrontLeftFenderDefectBtn(true);
    }

    if (step3.frontLeftFender === "Not Ok") {
      setFrontLeftFenderDefectBtn(true);
    }

    if (step3.roof === "Not Ok") {
      setRoofDefectBtn(true);
    }
  }, []);

  const updateCarPart = (part, value) => {
    setStep3((prevState) => {
      const updatedStep3 = {
        ...prevState,
        [part]: value,
      };
      localStorage.setItem(`${StepToken}_third`, JSON.stringify(updatedStep3));
      return updatedStep3;
    });
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        // autoHideDuration={15000}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <p className="text-center">
            Are You Sure You Want To Delete {defectId?.defect_type} Defect ?
          </p>

          <Button
            className="bg-danger text-white border-0 text-center fw-600"
            onClick={deleteDefect}
            fullWidth
          >
            Delete
          </Button>
        </Box>
      </Modal>

      {/* list modal  */}

      <Modal
        open={openCarSkeleton}
        onClose={() => setOpenCarSkeleton(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <div className="position-relative">
            <img
              src={carSkeleton}
              className=" object-fit-contain w-100"
              alt="car-skeleton"
            />

            {roofDefectTypes && (
              <div
                onClick={() => partId === "roof_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "36%",
                  top: "35%",
                  width: "28%",
                  height: "22%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {roofDefectTypes &&
                    roofDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftdoor_defect" && setOpenList(true)
                }
                className=" border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "8%",
                  bottom: "34%",
                  width: "18%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftDoorDefectTypes &&
                    frontLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {trunkDefectTypes && (
              <div
                onClick={() => partId === "trunk_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "32.5%",
                  top: "15%",
                  width: "35%",
                  height: "8%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {trunkDefectTypes &&
                    trunkDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {leftFootBandDefectTypes && (
              <div
                onClick={() =>
                  partId === "leftfootboard_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "2.5%",
                  top: "37%",
                  width: "6%",
                  height: "30%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {leftFootBandDefectTypes &&
                    leftFootBandDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearBumperDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearbumper_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "30%",
                  top: "3%",
                  width: "40%",
                  height: "9%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearBumperDefectTypes &&
                    rearBumperDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  top: "17.5%",
                  width: "13%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftFenderDefectTypes &&
                    rearLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  bottom: "16.5%",
                  width: "12%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftFenderDefectTypes &&
                    frontLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "7%",
                  top: "35%",
                  width: "26%",
                  height: "13%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftDoorDefectTypes &&
                    rearLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type.split("-")[0]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      {/* logic to open list modal  */}

      <Modal
        open={openList}
        onClose={() => setOpenList(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <select
            aria-label="Default select example"
            className="form-select btntextSize"
            onChange={(e) => setDefectedAreaPic(e.target.value)}
          >
            <option value="">Select</option>
            {defectsArr?.map((defect) => (
              <option value={defect.value}>{defect.name}</option>
            ))}
          </select>

          {!prevImg && (
            <Button
              variant="text"
              component="label"
              className="color-white LegendIcon bg p-2"
              fullWidth
              disabled={partId && defectedAreaPic !== "" ? false : true}
            >
              <span className=" me-2 text-capitalize">Add Image</span>{" "}
              <i className="fa-solid fa-plus"></i>
              <input
                name={partId}
                className="form-control visually-hidden"
                id={partId}
                // accept=".jpg,.jpeg,.png"
                accept="image/**"
                capture="user"
                type="file"
                onChange={(e) => handleDefectImageChange(e, partId)}
              />
            </Button>
          )}
          {prevImg && (
            <div className="position-relative">
              <img
                src={prevImg}
                className=" object-fit-contain w-100 rounded my-3"
                alt="defect image"
              />
              <button
                className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                style={{ right: -5, top: "7px" }}
                onClick={() => setPrevImg(null)}
              >
                X
              </button>
            </div>
          )}

          <Button
            className="color-white LegendIcon bg p-2 text-capitalize"
            fullWidth
            disabled={partId && defectedAreaPic !== "" ? false : true}
            onClick={() => {
              defectsTypes();
              setPrevImg(false);
              setOpenList(false);
            }}
          >
            {" "}
            Create{" "}
          </Button>
        </Box>
      </Modal>

      {/* image gallery  */}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_trunk"
                    className="form-label btntextSize text-black"
                  >
                    Trunk
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_trunk"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.trunk}
                      onChange={(e) => {
                        updateCarPart("trunk", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setTrunkDefectBtn(true);
                        } else {
                          setTrunkDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {trunkDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.trunk === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("trunk_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_trunkimage &&
                      images.iac_trunkimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_trunkimage);
                            setImageId("iac_trunkimage");
                            getImages("iac_trunkimage");
                          }}
                        >
                          {images.iac_trunkimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_trunkimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_trunkimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_trunkimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_trunkimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_bootfloor"
                    className="form-label btntextSize text-black"
                  >
                    Boot Floor
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_bootfloor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.bootFloor}
                      onChange={(e) =>
                        updateCarPart("bootFloor", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_bootfloorimage &&
                      images.iac_bootfloorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_bootfloorimage);
                            setImageId("iac_bootfloorimage");
                            getImages("iac_bootfloorimage");
                          }}
                        >
                          {images.iac_bootfloorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_bootfloorimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_bootfloorimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_bootfloorimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_bootfloorimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_bootlockpillartyresize"
                    className="form-label btntextSize text-black"
                  >
                    Boot Lock Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_bootlockpillartyresize"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.bootLockPillar}
                      onChange={(e) =>
                        updateCarPart("bootLockPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_bootlockpillarimage &&
                      images.iac_bootlockpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_bootlockpillarimage);
                            setImageId("iac_bootlockpillarimage");
                            getImages("iac_bootlockpillarimage");
                          }}
                        >
                          {images.iac_bootlockpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_bootlockpillarimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_bootlockpillarimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_bootlockpillarimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_bootlockpillarimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_floordesk"
                    className="form-label btntextSize text-black"
                  >
                    Floor Deck
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_floordesk"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.floorDeck}
                      onChange={(e) =>
                        updateCarPart("floorDeck", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_floordeckimage &&
                      images.iac_floordeckimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_floordeckimage);
                            setImageId("iac_floordeckimage");
                            getImages("iac_floordeckimage");
                          }}
                        >
                          {images.iac_floordeckimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_floordeckimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_floordeckimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_floordeckimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_floordeckimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_differentialoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Differential Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_differentialoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.differentialOilLeakage}
                      onChange={(e) =>
                        updateCarPart("differentialOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_differentialoilleakageimage &&
                      images.iac_differentialoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_differentialoilleakageimage
                            );
                            setImageId("iac_differentialoilleakageimage");
                            getImages("iac_differentialoilleakageimage");
                          }}
                        >
                          {images.iac_differentialoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_differentialoilleakageimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "iac_differentialoilleakageimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_differentialoilleakageimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "iac_differentialoilleakageimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_sparetire"
                    className="form-label btntextSize text-black"
                  >
                    Spare Tire
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_sparetire"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.spareTyre}
                      onChange={(e) =>
                        updateCarPart("spareTyre", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Present">Present</option>
                      <option value="Not Present">Not Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_sparetyreimage &&
                      images.iac_sparetyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_sparetyreimage);
                            setImageId("iac_sparetyreimage");
                            getImages("iac_sparetyreimage");
                          }}
                        >
                          {images.iac_sparetyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_sparetyreimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_sparetyreimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_sparetyreimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_sparetyreimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_tools"
                    className="form-label btntextSize text-black"
                  >
                    Tools
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_tools"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.tools}
                      onChange={(e) => updateCarPart("tools", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Complete">Complete</option>
                      <option value="Incomplete">Incomplete</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_toolsimage &&
                      images.iac_toolsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_toolsimage);
                            setImageId("iac_toolsimage");
                            getImages("iac_toolsimage");
                          }}
                        >
                          {images.iac_toolsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_toolsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_toolsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_toolsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_toolsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_jack"
                    className="form-label btntextSize text-black"
                  >
                    Jack
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_jack"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.jack}
                      onChange={(e) => updateCarPart("jack", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Present">Present</option>
                      <option value="Not Present or Not Working">
                        Not Present or Not Working
                      </option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_jackimage &&
                      images.iac_jackimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_jackimage);
                            setImageId("iac_jackimage");
                            getImages("iac_jackimage");
                          }}
                        >
                          {images.iac_jackimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_jackimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_jackimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_jackimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_jackimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_puncturerepairekit"
                    className="form-label btntextSize text-black"
                  >
                    Puncture Repaire Kit
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_puncturerepairekit"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.punctureRepairKit}
                      onChange={(e) =>
                        updateCarPart("punctureRepairKit", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Present">Present</option>
                      <option value="Not Present">Not Present</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_puncturerepairekitimage &&
                      images.iac_puncturerepairekitimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_puncturerepairekitimage
                            );
                            setImageId("iac_puncturerepairekitimage");
                            getImages("iac_puncturerepairekitimage");
                          }}
                        >
                          {images.iac_puncturerepairekitimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_puncturerepairekitimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_puncturerepairekitimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_puncturerepairekitimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_puncturerepairekitimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_trunklock"
                    className="form-label btntextSize text-black"
                  >
                    Trunk Lock
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_trunklock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.trunkLock}
                      onChange={(e) =>
                        updateCarPart("trunkLock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Ok with issues">Ok with issues</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_trunklockimage &&
                      images.iac_trunklockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_trunklockimage);
                            setImageId("iac_trunklockimage");
                            getImages("iac_trunklockimage");
                          }}
                        >
                          {images.iac_trunklockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_trunklockimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_trunklockimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_trunklockimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_trunklockimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_lefttaillightcondition"
                    className="form-label btntextSize text-black"
                  >
                    Left Taillight Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_lefttaillightcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftTailLightCondition}
                      onChange={(e) =>
                        updateCarPart("leftTailLightCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Foggy">Foggy</option>
                      <option value="Repaired">Repaired</option>
                      <option value="Cracked or Broken">
                        Cracked or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_lefttaillightimage &&
                      images.iac_lefttaillightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_lefttaillightimage);
                            setImageId("iac_lefttaillightimage");
                            getImages("iac_lefttaillightimage");
                          }}
                        >
                          {images.iac_lefttaillightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_lefttaillightimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_lefttaillightimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_lefttaillightimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_lefttaillightimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* rear bumper  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearbumper"
                    className="form-label btntextSize text-black"
                  >
                    Rear Bumper
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearbumper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearBumper}
                      onChange={(e) => {
                        updateCarPart("rearBumper", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearBumperDefectBtn(true);
                        } else {
                          setRearBumperDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearBumperDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearBumper === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearbumper_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearbumperimage &&
                      images.iac_rearbumperimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearbumperimage);
                            setImageId("iac_rearbumperimage");
                            getImages("iac_rearbumperimage");
                          }}
                        >
                          {images.iac_rearbumperimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearbumperimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearbumperimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearbumperimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearbumperimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* rear sub frame  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearsubframe"
                    className="form-label btntextSize text-black"
                  >
                    Rear Sub Frame
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearsubframe"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearSubFrame}
                      onChange={(e) =>
                        updateCarPart("rearSubFrame", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearsubframeimage &&
                      images.iac_rearsubframeimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearsubframeimage);
                            setImageId("iac_rearsubframeimage");
                            getImages("iac_rearsubframeimage");
                          }}
                        >
                          {images.iac_rearsubframeimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearsubframeimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearsubframeimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearsubframeimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearsubframeimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* rear windshield  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearwindshieldcondition"
                    className="form-label btntextSize text-black"
                  >
                    Rear Windshield Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearwindshieldcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearWindshieldCondition}
                      onChange={(e) =>
                        updateCarPart("rearWindshieldCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearwindshieldimage &&
                      images.iac_rearwindshieldimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearwindshieldimage);
                            setImageId("iac_rearwindshieldimage");
                            getImages("iac_rearwindshieldimage");
                          }}
                        >
                          {images.iac_rearwindshieldimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearwindshieldimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearwindshieldimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearwindshieldimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearwindshieldimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftdpiller"
                    className="form-label btntextSize text-black"
                  >
                    Left D Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftdpiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftDPillar}
                      onChange={(e) =>
                        updateCarPart("leftDPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftdpillarimage &&
                      images.iac_leftdpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftdpillarimage);
                            setImageId("iac_leftdpillarimage");
                            getImages("iac_leftdpillarimage");
                          }}
                        >
                          {images.iac_leftdpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftdpillarimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftdpillarimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftdpillarimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftdpillarimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearleftfender"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearleftfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearLeftFender}
                      onChange={(e) => {
                        updateCarPart("rearLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftFenderDefectBtn(true);
                        } else {
                          setRearLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearleftfenderimage &&
                      images.iac_rearleftfenderimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearleftfenderimage);
                            setImageId("iac_rearleftfenderimage");
                            getImages("iac_rearleftfenderimage");
                          }}
                        >
                          {images.iac_rearleftfenderimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearleftfenderimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearleftfenderimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearleftfenderimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearleftfenderimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftcpillar"
                    className="form-label btntextSize text-black"
                  >
                    Left C Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftcpillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftCPillar}
                      onChange={(e) =>
                        updateCarPart("leftCPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftcpillarimage &&
                      images.iac_leftcpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftcpillarimage);
                            setImageId("iac_leftcpillarimage");
                            getImages("iac_leftcpillarimage");
                          }}
                        >
                          {images.iac_leftcpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftcpillarimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftcpillarimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftcpillarimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftcpillarimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftfootboard"
                    className="form-label btntextSize text-black"
                  >
                    Left Foot Board
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftfootboard"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftFootBand}
                      onChange={(e) => {
                        updateCarPart("leftFootBand", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setLeftFootBandDefectBtn(true);
                        } else {
                          setLeftFootBandDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {leftFootBandDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.leftFootBand === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("leftfootboard_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftfootboardimage &&
                      images.iac_leftfootboardimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftfootboardimage);
                            setImageId("iac_leftfootboardimage");
                            getImages("iac_leftfootboardimage");
                          }}
                        >
                          {images.iac_leftfootboardimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftfootboardimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftfootboardimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftfootboardimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftfootboardimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearleftdoor"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearleftdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearLeftDoor}
                      onChange={(e) => {
                        updateCarPart("rearLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftDoorDefectBtn(true);
                        } else {
                          setRearLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearleftdoorimage &&
                      images.iac_rearleftdoorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearleftdoorimage);
                            setImageId("iac_rearleftdoorimage");
                            getImages("iac_rearleftdoorimage");
                          }}
                        >
                          {images.iac_rearleftdoorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearleftdoorimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearleftdoorimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_rearleftdoorimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_rearleftdoorimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftbpiller"
                    className="form-label btntextSize text-black"
                  >
                    Left B Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftbpiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftBPillar}
                      onChange={(e) =>
                        updateCarPart("leftBPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftbpillarimage &&
                      images.iac_leftbpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftbpillarimage);
                            setImageId("iac_leftbpillarimage");
                            getImages("iac_leftbpillarimage");
                          }}
                        >
                          {images.iac_leftbpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftbpillarimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftbpillarimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftbpillarimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftbpillarimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_frontleftdoor"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_frontleftdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.frontLeftDoor}
                      onChange={(e) => {
                        updateCarPart("frontLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftDoorDefectBtn(true);
                        } else {
                          setFrontLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.frontLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_frontleftdoorimage &&
                      images.iac_frontleftdoorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_frontleftdoorimage);
                            setImageId("iac_frontleftdoorimage");
                            getImages("iac_frontleftdoorimage");
                          }}
                        >
                          {images.iac_frontleftdoorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_frontleftdoorimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_frontleftdoorimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_frontleftdoorimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_frontleftdoorimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftsidemirror"
                    className="form-label btntextSize text-black"
                  >
                    Left Side Mirror
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftsidemirror"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftMirror}
                      onChange={(e) =>
                        updateCarPart("leftMirror", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Broken or Not Rotating">
                        Broken OR Not Rotating
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftsidemirrorimage &&
                      images.iac_leftsidemirrorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftsidemirrorimage);
                            setImageId("iac_leftsidemirrorimage");
                            getImages("iac_leftsidemirrorimage");
                          }}
                        >
                          {images.iac_leftsidemirrorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftsidemirrorimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftsidemirrorimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftsidemirrorimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftsidemirrorimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* left a pillar  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftapiller"
                    className="form-label btntextSize text-black"
                  >
                    Left A Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftapiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftAPillar}
                      onChange={(e) =>
                        updateCarPart("leftAPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftapillarimage &&
                      images.iac_leftapillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftapillarimage);
                            setImageId("iac_leftapillarimage");
                            getImages("iac_leftapillarimage");
                          }}
                        >
                          {images.iac_leftapillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftapillarimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftapillarimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_leftapillarimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_leftapillarimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* front left fender  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_frontleftfender"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_frontleftfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.frontLeftFender}
                      onChange={(e) => {
                        updateCarPart("frontLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftFenderDefectBtn(true);
                        } else {
                          setFrontLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.frontLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_frontleftfenderimage &&
                      images.iac_frontleftfenderimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_frontleftfenderimage);
                            setImageId("iac_frontleftfenderimage");
                            getImages("iac_frontleftfenderimage");
                          }}
                        >
                          {images.iac_frontleftfenderimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_frontleftfenderimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_frontleftfenderimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_frontleftfenderimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_frontleftfenderimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_roof"
                    className="form-label btntextSize text-black"
                  >
                    Roof
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_roof"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.roof}
                      onChange={(e) => {
                        updateCarPart("roof", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRoofDefectBtn(true);
                        } else {
                          setRoofDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {roofDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.roof === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("roof_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_roofimage &&
                      images.iac_roofimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_roofimage);
                            setImageId("iac_roofimage");
                            getImages("iac_roofimage");
                          }}
                        >
                          {images.iac_roofimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_roofimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_roofimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_roofimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_roofimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* sun roof glasses  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_sunroofglasses"
                    className="form-label btntextSize text-black"
                  >
                    Sun Roof Glass
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_sunroofglasses"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.sunRoofGlass}
                      onChange={(e) =>
                        updateCarPart("sunRoofGlass", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Crack">Crack</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_runroofglassesimage &&
                      images.iac_runroofglassesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_runroofglassesimage);
                            setImageId("iac_runroofglassesimage");
                            getImages("iac_runroofglassesimage");
                          }}
                        >
                          {images.iac_runroofglassesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_runroofglassesimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_runroofglassesimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_runroofglassesimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_runroofglassesimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* ladder frame  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_ladderframe"
                    className="form-label btntextSize text-black"
                  >
                    Ladder Frame
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_ladderframe"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.ladderFrame}
                      onChange={(e) =>
                        updateCarPart("ladderFrame", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>

                    <br />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_ladderframeimage &&
                      images.iac_ladderframeimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_ladderframeimage);
                            setImageId("iac_ladderframeimage");
                            getImages("iac_ladderframeimage");
                          }}
                        >
                          {images.iac_ladderframeimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_ladderframeimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_ladderframeimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_ladderframeimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_ladderframeimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* starter option  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_starteroperation"
                    className="form-label btntextSize text-black"
                  >
                    Starter Operation
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_starteroperation"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.starterOperation}
                      onChange={(e) =>
                        updateCarPart("starterOperation", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_starteroperationimage &&
                      images.iac_starteroperationimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_starteroperationimage
                            );
                            setImageId("iac_starteroperationimage");
                            getImages("iac_starteroperationimage");
                          }}
                        >
                          {images.iac_starteroperationimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_starteroperationimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_starteroperationimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_starteroperationimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_starteroperationimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {/* cold start  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_coldstart"
                    className="form-label btntextSize text-black"
                  >
                    Cold Start
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_coldstart"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.coldStart}
                      onChange={(e) =>
                        updateCarPart("coldStart", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_coldstartimage &&
                      images.iac_coldstartimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_coldstartimage);
                            setImageId("iac_coldstartimage");
                            getImages("iac_coldstartimage");
                          }}
                        >
                          {images.iac_coldstartimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_coldstartimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_coldstartimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="iac_coldstartimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "iac_coldstartimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="iac_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="iac_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4 d-flex gap-3 justify-content-center">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    disabled={
                      pending === true ||
                      isSubmitting === true ||
                      isSubmittingFields === true
                        ? true
                        : false
                    }
                    className=" px-5 color-white"
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step7;
