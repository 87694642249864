import React, { useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import ShortInspectionReports from "../components/ads/short-inspection-history";

export default function ShortInspectionHistory() {
  const { user } = React.useContext(AuthContext);
  const history = useHistory();
  // useEffect(() => {
  //   document.title = "Bidding History | FameWheels";
  //   if (!user) {
  //     history.push("/")
  //   }
  // }, []);
  return (
    <>
      <ShortInspectionReports />
    </>
  );
}
