import * as React from "react";
import "../Styles/style.css";
import { useEffect, useState } from "react";
import axios from "axios";
// import baseUrl from "../../api/ipconfig";
import MyadsNavigation from "./myads-navigation";
import UserDetails from "./userDetails";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InnerLoader from "../loader/innerLoader";
import baseUrl from "../../api/ipconfig";
import { AuthContext } from "../../context/AuthContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShortInspectionReports() {
  const { user } = React.useContext(AuthContext);

  const [inspectionHistory, setInspectionHistory] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [statusMsg, setStatusMsg] = useState("");
  const [isLoading, setLoading] = useState(true);

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const user = localStorage.getItem("data");
  // const userId = JSON.parse(user);

  useEffect(() => {
    const fetchBiddingHistory = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${baseUrl}/inspectorshortinspectionlist`,
          {
            params: {
              shortinspection_status: "Complete",
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInspectionHistory(response.data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment history", error);
      }
    };
    fetchBiddingHistory();
  }, []);

  const handleButtonClick = (token) => {
    window.open(
      `https://inspectionreport.famewheels.com/inspection-report/short/${token}`
    );
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleBarClose}
          severity="success"
          sx={{ width: "100%" }}
        ></Alert>
      </Snackbar>
      <div className="MyAds_main">
        <UserDetails />
        <div className="container-md MyAds_body">
          <div className="row pb-5">
            <div className="col-lg-2 p-0">
              <MyadsNavigation />
            </div>
            <div className="col-lg-10 ">
              {isLoading ? (
                <InnerLoader />
              ) : inspectionHistory?.length ? (
                <>
                  <div className=" bgSecondary boxShadow mb-3 d-none d-md-none">
                    <div className="row paymentHistory_header">
                      <div className="col-lg-2 col-2">
                        <h6>Date</h6>
                      </div>
                      <div className="col-lg-2 col-2">
                        <h6>Engine No.</h6>
                      </div>
                      <div className="col-lg-3 col-3">
                        <h6>Registered No.</h6>
                      </div>
                      <div className="col-lg-2 col-2">
                        <h6>Location</h6>
                      </div>
                      <div className="col-lg-3 col-3"></div>
                    </div>
                  </div>
                  {inspectionHistory?.map((item) => (
                    <div
                      key={item?.shortinspection_id}
                      className=" bg-white paymentHistory_list boxShadow mb-3"
                    >
                      <div className="row align-items-center py-2 ms-2">
                        <div className="col-lg-3 col-3">
                          <h6> {item?.shortinspection_city} </h6>
                        </div>
                        <div className="col-lg-3 col-3">
                          <h6>
                            {" "}
                            {item?.shortinspection_make}{" "}
                            {item?.shortinspection_model}{" "}
                            {item?.shortinspection_year}{" "}
                          </h6>
                        </div>
                        <div className="col-lg-3 col-3">
                          <h6> {item?.shortinspection_registrationno} </h6>
                        </div>

                        <div className="col-lg-3 col-3 d-flex justify-content-end ">
                          <Button
                            variant="contained"
                            className=" bgSecondary color-white me-3"
                            onClick={() =>
                              handleButtonClick(item?.shortinspection_token)
                            }
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="adsSection">
                  <div className="No_ads text-center">
                    <p>no history available</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
