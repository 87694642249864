import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import baseUrl from "../../../api/ipconfig";
import { Form } from "react-bootstrap";
import InspectionSuccess from "../../modals/inspection-success";
import { useHistory } from "react-router-dom";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";
import s3 from "../../../config/s3Config";

const Step2 = ({ onSuccess, previouse, user, userData }) => {
  const token = localStorage.getItem("token");

  const history = useHistory();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [payment, setPayment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [securityDeposit, setsSecurityDeposit] = useState(null);
  const [paymentToken, setsPaymentToken] = useState("");
  const [paymentStatus, setsPaymentStatus] = useState("");
  const [openPayment, setOpenPayment] = useState(false);

  const formRef = useRef(null);
  const btnRef = useRef(null);
  const [successIsOpen, setSuccessIsOpen] = React.useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);
  const [drafts, setDrafts] = useState([]);

  const [isSycc, setIsSync] = useState(null);

  const LoginClose = () => {
    setSuccessIsOpen(false);
  };

  const [stepImages, setStepImages] = useState(null);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");
  const inspectionId = extractTokenFromUrl(url, "inspection");
  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 1500);
  }, []);

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  // this will goto to step 8

  // const handleDeleteAllImages = async () => {
  //   try {

  //     const db = await openDB("my-database", 1);

  //     // Open a transaction to access the object store
  //     const tx = db.transaction("images", "readwrite");
  //     const store = tx.objectStore("images");

  //     // Delete the image with the specified id

  //     for (const category in images) {
  //       if (images.hasOwnProperty(category)) {
  //         for (const image of images[category]) {
  //           await store.delete(image.id);
  //           // console.log(image)
  //         }
  //       }
  //     }

  //   } catch (error) {
  //     console.log("delete image error", error);
  //     setErrOpen(true);
  //     setError("Couldn't delete image");
  //   } finally {
  //     // await getLocalImages();
  //   }
  // };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  useEffect(() => {
    const fetchPendingInspections = async () => {
      const getData = localStorage.getItem("inspection_draft");

      const data = JSON.parse(getData);

      //console.log(data)

      setDrafts([...data]);
    };
    fetchPendingInspections();
  }, []);

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
    // setGalleryImages(images);
  };
  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    // Open the IndexedDB database
    const db = await openDB("my-database", 1, {
      upgrade(db) {
        // Check if the object store exists before creating it
        if (!db.objectStoreNames.contains("images")) {
          // Create an object store for storing images
          db.createObjectStore("images", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });

    // Iterate over each file
    for (const file of files) {
      const options = {
        maxSizeMB: 0.06,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      //console.log("Compressed file:", compressedFile);

      const compressedImageFile = new File([compressedFile], file.name, {
        type: file.type,
      });

      const imageData = {
        name: fieldName,
        image: compressedImageFile,
        token: StepToken,
        inspection_step: 8,
      };

      try {
        // Store the image data in IndexedDB
        const tx = db.transaction("images", "readwrite");
        const store = tx.objectStore("images");
        await store.add(imageData);
        await tx.done;
        //console.log("Image uploaded to IndexedDB successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Retrieve the stored images from IndexedDB
    await getLocalImages();
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 7);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (isOnline === false) {
      fetchDraftPending();
    }
  }, [token]);

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = filteredImagesData.filter(
        (images) => images.inspection_step === 8
      );

      setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          // const formData = new FormData();

          // // Append common form data fields
          // formData.append("iinitial_token", StepToken);
          // formData.append("inspectionstep_id", 2);

          // // Append each image to formData
          // formData.append(`file[]`, image.image);
          // formData.append(`image_name`, image.name); // Assuming name is an array

          // //console.log("FormData:", formData.get("image_name"));

          // const response = await axios.post(uploadEndpoint, formData, {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          //console.log("API Response:", response.data);

          const s3Key = `public/inspection/${StepToken}/${image?.name}/${image?.image?.name}`;
  
          const params = {
            Bucket: 'famewheels',
            Key: s3Key,
            Body: image.image,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: image.image.type,
          };
  
          const response = await s3.upload(params).promise();

          const url = response.Key;
 
           const ImageName = url.substring(url.lastIndexOf('/') + 1);
 
           const formData = new FormData();
           formData.append("image_name", image?.name);
           formData.append("iinitial_token", StepToken);
           formData.append("inspectionstep_id", 7);
           formData.append(`file`, ImageName);
 
           const responseApi = await axios.post(
             `${baseUrl}/saveinspectionimage`,
             formData,
             {
               headers: {
                 Authorization: `Bearer ${token}`,
                 "Content-Type": "multipart/form-data",
               },
             }
           );
          await handleDeleteImage(image.id, image.id);
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  // this will also goto step 8

  // const deleteDefectByStepToken = async () => {
  //   try {
  //     const db = await openDB("defects-database", 1, {
  //       upgrade(db) {
  //         if (!db.objectStoreNames.contains("defects")) {
  //           const objectStore = db.createObjectStore("defects", {
  //             keyPath: "id",
  //             autoIncrement: true,
  //           });
  //         }
  //       },
  //     });

  //     const transaction = db.transaction("defects", "readonly");

  //     const objectStore = transaction.objectStore("defects");

  //     const stored = await objectStore.getAll();

  //           const tx = db.transaction("defects", "readwrite");
  //           const store = tx.objectStore("defects");

  //           // // Delete the image with the specified id
  //           // await store.delete(defectId?.id);

  //     if (stored) {
  //       const filteredDefects = stored.filter(
  //         (defect) => defect.initial_token === StepToken
  //       );

  //       for (const defect of filteredDefects) {
  //             await store.delete(defect.id);
  //       }

  //     }
  //   } catch (error) {
  //     console.error("Error retrieving defects from IndexedDB:", error);
  //   }
  // };

  useEffect(() => {
    getLocalImages();
  }, []);

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_seventh`);
      if (formData) {
        const newData = JSON.parse(formData);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "8";
            obj.token = StepToken;
          }
        });

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_seventh`, serializedFormData);

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        // history.push("/syncoffline")
        onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 7");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  // useEffect(()=>{
  //   const getInpectionId = async ()=>{
  //     try {
  //       const response = await axios.get(`${baseUrl}/inspectionrequestdetail`,{
  //         params:{
  //           inspection_id:inspectionId
  //         }
  //       })

  //       setsPaymentStatus(response?.data?.vehicledetail?.payment_status)

  //     } catch (error) {
  //       console.error(
  //         error
  //       )
  //     }
  //   }

  //   getInpectionId()

  // },[inspectionId])

  //console.log("inspectionId ==== ", inspectionId)

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const CreateUser = async () => {
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };

        params.data.append("userId", userData?.id);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse?.iinitial_id
        );
        params.data.append("iinitial_token", StepToken);
        params.data.append("is_edit", 0);

        const response = await axios.post(
          `${baseUrl}/finishinspection`,
          params.data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // if (Array.isArray(drafts)) {
        //     const draftFiltered = drafts.filter((e) => {
        //         //console.log("Comparison:", e.inspection_id, parseInt(inspectionId));
        //         return e.inspection_id !== parseInt(inspectionId);
        //     });

        //     //console.log("draftFiltered filtered ====== ", draftFiltered);
        //     // Update localStorage with filtered data
        //     localStorage.setItem("inspection_draft", JSON.stringify(draftFiltered));
        // } else {
        //     //console.log("Drafts is undefined.");
        // }

        // localStorage.removeItem(`${StepToken}_first`)
        // localStorage.removeItem(`${StepToken}_second`);
        // localStorage.removeItem(`${StepToken}_third`)
        // localStorage.removeItem(`${StepToken}_fourth`)
        // localStorage.removeItem(`${StepToken}_fifth`)
        // localStorage.removeItem(`${StepToken}_sixth`)
        // localStorage.removeItem(`${StepToken}_seventh`)
        // localStorage.removeItem(`${StepToken}_eighth`);
        // localStorage.removeItem(`${StepToken}_userData`);

        //console.log("====================================");
        //console.log(response);
        //console.log("====================================");
        // setIsSubmitting(false);
        formRef.current.hasUnsavedChanges = false;
      }
      setIsSubmitting(false);

      // setSuccessIsOpen(true);
      // setTimeout(() => {
      //     setSuccessIsOpen(false);
      //     window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
      //     history.push(`/inspection-history`);
      // }, 1000);
      onSuccess();

      // if (paymentStatus !== "paid") {

      //   setOpenPayment(true)

      // }else{
      //   setSuccessIsOpen(true);

      //   setTimeout(() => {
      //     setSuccessIsOpen(false);
      //     window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
      //     history.push(`/inspection-history`);
      //   }, 4000);
      // }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setPending(true);
        setPendingData("Completing Step 8");
        await CreateUser();
      } catch (e) {
        console.error(e);
        setErrOpen(true);
        setError(`Step 8 Submit Failed!`);
      } finally {
        setPending(false);
        setIsSubmittingFields(false);
        setIsSubmitting(false);
      }
      btnRef.current?.setLoader(false);
    }
  };

  const handleSave = async () => {
    try {
      await postImages();
      await handleSaveFields();
      // await deleteDefectByStepToken()
      // await handleDeleteAllImages()
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 500);
    }
  }, [isSycc]);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("payment_status", payment);
    formData.append("payment_method", paymentMethod);
    formData.append("security_deposit", securityDeposit);
    formData.append("payment_token", paymentToken);
    formData.append("inspection_id", inspectionId);

    try {
      const response = await axios.post(
        `${baseUrl}/inspection-payment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessIsOpen(true);

      if (response?.status === 200) {
        setTimeout(() => {
          setSuccessIsOpen(false);
          window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
          history.push(`/inspection-history`);
        }, 4000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <Modal
        open={openPayment}
        onClose={() => {
          setOpenPayment(false);
        }}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="md-modal position-relative p-3 p-md-4">
          <form
            className="w-100 p-3 mt-4 rounded-4"
            onSubmit={handlePaymentSubmit}
          >
            <h4 className="textSecondary">Payment Information</h4>

            <hr className="text-black" />

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label
                  for="payment_status"
                  className="form-label text-black btntextSize"
                >
                  Payment Recieved
                </label>
                <select
                  name="payment_status"
                  className="form-select btntextSize"
                  id="payment_status"
                  aria-label="Default select example"
                  required
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <option selected value="">
                    Select Payment
                  </option>
                  <option value="paid">Paid</option>
                  <option value="un_paid">Un Paid</option>
                </select>
              </div>

              {payment === "paid" && (
                <>
                  <div className="col-lg-6 mb-3">
                    <label
                      for="security_deposit"
                      className="form-label text-black btntextSize"
                    >
                      Inspection Amount
                    </label>
                    <select
                      name="security_deposit"
                      className="form-select btntextSize"
                      id="security_deposit"
                      aria-label="Default select example"
                      required
                      value={securityDeposit}
                      onChange={(e) => setsSecurityDeposit(e.target.value)}
                    >
                      <option selected value="">
                        Select Amount
                      </option>
                      <option value={2000}>2000</option>
                      <option value={4000}>4000</option>
                      <option value={6000}>6000</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label
                      for="payment_method"
                      className="form-label text-black btntextSize"
                    >
                      Payment Mehtod
                    </label>
                    <select
                      name="payment_method"
                      className="form-select btntextSize"
                      id="payment_method"
                      aria-label="Default select example"
                      required
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option selected value="">
                        Select Payment Mehtod
                      </option>
                      <option value={"cash"}>Cash</option>
                      <option value={"online"}>Online</option>
                    </select>
                  </div>

                  {paymentMethod === "online" && (
                    <div className="col-lg-6 mb-3">
                      <label
                        for="payment_token"
                        className="form-label text-black btntextSize"
                      >
                        Transection Id
                      </label>
                      <input
                        name="payment_token"
                        type="text"
                        className="form-control p-2 w-100 btntextSize"
                        id="payment_token"
                        aria-describedby="titleHelp"
                        required
                        placeholder="Transaction Id"
                        value={paymentToken}
                        onChange={(e) => setsPaymentToken(e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <Button
              type="submit"
              className="color-white LegendIcon bg p-2 text-capitalize"
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="frontview"
                  className="form-label btntextSize text-black"
                >
                  Front View Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_front_view_image &&
                    images.iex_front_view_image.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_front_view_image);
                          setImageId("iex_front_view_image");
                          getImages("iex_front_view_image");
                        }}
                      >
                        {images.iex_front_view_image.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_front_view_image"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_front_view_image")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_front_view_image"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_front_view_image")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquefrontright"
                  className="form-label btntextSize text-black"
                >
                  Oblique Front- Right
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_front_right &&
                    images.iex_oblique_front_right.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_front_right);
                          setImageId("iex_oblique_front_right");
                          getImages("iex_oblique_front_right");
                        }}
                      >
                        {images.iex_oblique_front_right.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_front_right"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_front_right")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_front_right"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_front_right")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquerearright"
                  className="form-label btntextSize text-black"
                >
                  Oblique Rear- Right
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_rear_right &&
                    images.iex_oblique_rear_right.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_rear_right);
                          setImageId("iex_oblique_rear_right");
                          getImages("iex_oblique_rear_right");
                        }}
                      >
                        {images.iex_oblique_rear_right.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_rear_right"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_rear_right")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_rear_right"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_rear_right")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="trunkopenpic"
                  className="form-label btntextSize text-black"
                >
                  Trunk Open
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_trunk_open_pic &&
                    images.iex_trunk_open_pic.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_trunk_open_pic);
                          setImageId("iex_trunk_open_pic");
                          getImages("iex_trunk_open_pic");
                        }}
                      >
                        {images.iex_trunk_open_pic.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_trunk_open_pic"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_trunk_open_pic")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_trunk_open_pic"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_trunk_open_pic")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="rearviewimage"
                  className="form-label btntextSize text-black"
                >
                  Rear View Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_rear_view_image &&
                    images.iex_rear_view_image.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_rear_view_image);
                          setImageId("iex_rear_view_image");
                          getImages("iex_rear_view_image");
                        }}
                      >
                        {images.iex_rear_view_image.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_rear_view_image"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_rear_view_image")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_rear_view_image"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_rear_view_image")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquerearleft"
                  className="form-label btntextSize text-black"
                >
                  Oblique Rear- Left
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_rear_left &&
                    images.iex_oblique_rear_left.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_rear_left);
                          setImageId("iex_oblique_rear_left");
                          getImages("iex_oblique_rear_left");
                        }}
                      >
                        {images.iex_oblique_rear_left.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_rear_left"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_rear_left")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_rear_left"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_rear_left")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="obliquefrontleft"
                  className="form-label btntextSize text-black"
                >
                  Oblique Front- Left
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_oblique_front_left &&
                    images.iex_oblique_front_left.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_oblique_front_left);
                          setImageId("iex_oblique_front_left");
                          getImages("iex_oblique_front_left");
                        }}
                      >
                        {images.iex_oblique_front_left.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_front_left"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_front_left")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_oblique_front_left"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_oblique_front_left")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="vehiclekeys"
                  className="form-label btntextSize text-black"
                >
                  Vehicle Keys Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_vehicle_keys &&
                    images.iex_vehicle_keys.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_vehicle_keys);
                          setImageId("iex_vehicle_keys");
                          getImages("iex_vehicle_keys");
                        }}
                      >
                        {images.iex_vehicle_keys.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_vehicle_keys"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_vehicle_keys")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_vehicle_keys"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_vehicle_keys")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="odometer"
                  className="form-label btntextSize text-black"
                >
                  Odo Meter Image
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_odo_meter &&
                    images.iex_odo_meter.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_odo_meter);
                          setImageId("iex_odo_meter");
                          getImages("iex_odo_meter");
                        }}
                      >
                        {images.iex_odo_meter.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_odo_meter"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) => handleImageChange(e, "iex_odo_meter")}
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_odo_meter"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) => handleImageChange(e, "iex_odo_meter")}
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="enginecompartment"
                  className="form-label btntextSize text-black"
                >
                  Engine Compartment
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_engine_compartment &&
                    images.iex_engine_compartment.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_engine_compartment);
                          setImageId("iex_engine_compartment");
                          getImages("iex_engine_compartment");
                        }}
                      >
                        {images.iex_engine_compartment.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_engine_compartment"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_engine_compartment")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_engine_compartment"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_engine_compartment")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="enginenumber"
                  className="form-label btntextSize text-black"
                >
                  Engine Number
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_engine_number &&
                    images.iex_engine_number.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_engine_number);
                          setImageId("iex_engine_number");
                          getImages("iex_engine_number");
                        }}
                      >
                        {images.iex_engine_number.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_engine_number"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_engine_number")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_engine_number"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_engine_number")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="chassisnumber"
                  className="form-label btntextSize text-black"
                >
                  Chassis Number
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_chassis_number &&
                    images.iex_chassis_number.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_chassis_number);
                          setImageId("iex_chassis_number");
                          getImages("iex_chassis_number");
                        }}
                      >
                        {images.iex_chassis_number.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_chassis_number"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_chassis_number")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_chassis_number"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_chassis_number")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="chassisplate"
                  className="form-label btntextSize text-black"
                >
                  Chassis Plate
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_chassis_plate &&
                    images.iex_chassis_plate.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_chassis_plate);
                          setImageId("iex_chassis_plate");
                          getImages("iex_chassis_plate");
                        }}
                      >
                        {images.iex_chassis_plate.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_chassis_plate"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_chassis_plate")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_chassis_plate"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_chassis_plate")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="frontinterior"
                  className="form-label btntextSize text-black"
                >
                  Front Interior
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_front_interior &&
                    images.iex_front_interior.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_front_interior);
                          setImageId("iex_front_interior");
                          getImages("iex_front_interior");
                        }}
                      >
                        {images.iex_front_interior.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_front_interior"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_front_interior")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_front_interior"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_front_interior")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="backinterior"
                  className="form-label btntextSize text-black"
                >
                  Back Interior
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_back_interior &&
                    images.iex_back_interior.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_back_interior);
                          setImageId("iex_back_interior");
                          getImages("iex_back_interior");
                        }}
                      >
                        {images.iex_back_interior.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_back_interior"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_back_interior")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_back_interior"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_back_interior")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="fourway_switch"
                  className="form-label btntextSize text-black"
                >
                  4 Way Switch
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_fourwayswitchimage &&
                    images.iex_fourwayswitchimage.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_fourwayswitchimage);
                          setImageId("iex_fourwayswitchimage");
                          getImages("iex_fourwayswitchimage");
                        }}
                      >
                        {images.iex_fourwayswitchimage.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_fourwayswitchimage"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_fourwayswitchimage")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_fourwayswitchimage"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_fourwayswitchimage")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <label
                  for="extrapictures"
                  className="form-label btntextSize text-black"
                >
                  Extra Pictures
                </label>

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.iex_extra_images &&
                    images.iex_extra_images.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.iex_extra_images);
                          setImageId("iex_extra_images");
                          getImages("iex_extra_images");
                        }}
                      >
                        {images.iex_extra_images.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_extra_images"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_extra_images")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="iex_extra_images"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iex_extra_images")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-12 mt-3">
                <label
                  for="inspector_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="inspector_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center d-flex justify-content-center align-items-center gap-2 pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    disabled={pending === true || isSubmitting === true}
                    className=" px-5 color-white"
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
        <InspectionSuccess open={successIsOpen} onClose={LoginClose} />
      </div>
    </>
  );
};

export default Step2;
