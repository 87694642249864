import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import setAuthToken from "../../auth/auth.js";
import "../Styles/style.css";
import logo from "../../images/fame-wheels-logo.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PostFW from "../../images/post-on-fw.png";
import SellFW from "../../images/sell-to-fw.png";
import { NavLink, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import baseUrl, { baseStepsUrl } from "../../api/ipconfig.js";
// import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BottomNavigation from "@mui/material/BottomNavigation";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import StreamRoundedIcon from "@mui/icons-material/StreamRounded";
import BlueTick from "../../images/blue-tick-success.png";
import SuccessTick from "../../images/success-tick.gif";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import CreditIcon from "../../images/credits-icon.png";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Crown from "../../images/crown.png";
import Upcomming from "../../images/upcoming.png";
import LiveBid from "../../images/auction.png";
import InspectionIcon from "../../images/inspectionIcon.png";
import InspectionOrders from "../../images/inspection-orders.png";
import SellThroughBid from "../../images/bidding-selling.png";
import UsedCars from "../../images/used-cars.png";
import SellCar from "../../images/sellcar.png";
// import InternetStatus from "../modals/internet-status.jsx";
import jwtDecode from "jwt-decode";
import { useAuthContext } from "../../hooks/useAuthContext.js";
import Cookies from "js-cookie";
import { AuthContext } from "../../context/AuthContext.js";
import { useHistory } from "react-router-dom";
// import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
// import SpeedDialAction from "@mui/material/SpeedDialAction";
// import HistoryIcon from "@mui/icons-material/History";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import PakistanFlag from "../../images/pakistan.png";
import SendIcon from "@mui/icons-material/Send";

// const actions = [
//   {
//     icon: <QuestionAnswerOutlinedIcon sx={{ color: "#ffffff" }} />,
//     name: "Contact Support",
//   },
//   { icon: <HistoryIcon sx={{ color: "#ffffff" }} />, name: "Support History" },
// ];

const xorEncrypt = (data, key) => {
  const encryptedData = data.split("").map((char, i) => {
    const keyChar = key.charCodeAt(i % key.length);
    const encryptedChar = char.charCodeAt(0) ^ keyChar;
    return String.fromCharCode(encryptedChar);
  });
  return encryptedData.join("");
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Header() {
  const [speedOpen, setSpeedOpen] = React.useState(false);
  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const [waMassage, setWaMessage] = useState(null);

  const handleSpeedToggle = () => {
    setSpeedOpen(!speedOpen);
    setOpenWhatsapp(false);
    setWaMessage(null);
  };

  const phoneNumber = "03022856567";
  const formattedPhoneNumber = phoneNumber ? `92${phoneNumber.slice(1)}` : "";

  const openWhatsApp = () => {
    const whatsappUrl = `http://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodeURIComponent(
      waMassage
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  // const handleSpeedClose = () => setSpeedOpen(false);

  const { dispatch } = useAuthContext();
  const { user } = React.useContext(AuthContext);

  const [isOnline, setIsOnline] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    if (password !== value) {
      setPasswordMatchError("Passwords did not match");
    } else {
      setPasswordMatchError("");
    }
  };

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    handleConnectionChange();

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  // const handleReload = () => {
  //   // Reload the page when the user clicks the reload button
  //   window.location.reload();
  // };

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token"); // Get the JWT token from local storage

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decodedToken.exp < currentTime) {
        // Token has expired
        setIsSessionExpired(true);
      }
    } else {
      // Token is missing, handle accordingly (e.g., log the user out)
    }
  }, []);

  // const { setUserContext } = useUserContext();

  const [errOpen, setErrOpen] = React.useState(false);

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrOpen(false);
  };

  const [postOpen, setPostOpen] = React.useState(false);
  // const handlePostOpen = () => setPostOpen(true);

  const handlePostClose = () => setPostOpen(false);

  const [loginOpen, setLoginOpen] = React.useState(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  const [signupOpen, setSignupOpen] = React.useState(false);
  const handleSignupOpen = () => setSignupOpen(true);
  const handleSignupClose = () => setSignupOpen(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [successLogin, setSuccessLogin] = useState(false);
  const [successSignup, setSuccessSignup] = useState(false);
  // const [user, setUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignSubmitting, setIsSignSubmitting] = useState(false);
  // const [userDetails, setUserDetails] = useState(" ");
  const [roleName, setRoleName] = useState(" ");
  const [userName, setUserName] = useState(" ");
  const [userCredit, setUserCredit] = useState(null);
  const [cities, setCities] = useState(null);
  const [vehicleCategory, setVehicleCategory] = useState(null);
  // const [make, setMake] = useState(null);

  useEffect(() => {
    // //console.log("========useeffect++++");
    // let replicafetchData = localStorage.getItem("data");
    // let fetchData = JSON.parse(replicafetchData);
    // setUser(fetchData);
    setRoleName("ROLE_USER");
  }, []);

  // const history = useHistory();

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const token = localStorage.getItem("token");

  //       const response = await axios.get(`${baseUrl}/getUser`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       //console.log(response.data, "user details ====");
  //       setUserDetails(response.data);
  //       // setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching user posts:", error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchUserDetails();
  // }, []);

  const history = useHistory();

  // useEffect(() => {
  //   const fetchCredits = async () => {
  //     try {
  //       const token = localStorage.getItem("token");

  //       const response = await axios.get(`${baseUrl}/fame/getTotalAmount`, {
  //         params: {
  //           userId: user?.id,
  //         },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       // //console.log(response.data, "user credits");
  //       setUserCredit(response.data);
  //       // setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching user posts:", error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchCredits();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // all cities api

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${baseUrl}/cities`);

        // //console.log("All Cities", response.data);
        setCities(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCity();
  }, []);

  const limitedCity = cities?.slice(0, 10);

  const openByCity = (cityID) => {
    history.push(`/search?city=${cityID}`);
  };

  // all categories api

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/bycategory`);

        // //console.log("All Categories", response.data);
        setVehicleCategory(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const limitedCategory = vehicleCategory?.slice(0, 10);

  const openCategory = (category) => {
    history.push(`/search/category?${category}`);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsSignSubmitting(true);
    try {
      const formData = new FormData();

      // Append form field values
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("phone", phone);
      formData.append("roleName", roleName);

      const response = await axios.post(`${baseUrl}/getUsers/new`, formData);
      // localStorage.setItem("token", response.data.token);
      setAuthToken(response.data.token);
      setSuccessSignup(true);
      setIsSignSubmitting(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      //console.log(response, " signup details ");
    } catch (err) {
      setError(err?.response?.data);
      setIsSignSubmitting(false);
      setErrOpen(true);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${baseUrl}/login`, {
        email: userName,
        password,
      });

      //console.log(response.data);

      localStorage.setItem("token", response.data.token);
      setAuthToken(response.data.token);
      setSuccessLogin(true);
      setIsSubmitting(false);
      // window.location.reload();

      let resp = response.data.token;
      let base64Url = resp.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const data = JSON.parse(jsonPayload);
      localStorage.setItem("data", JSON.stringify(data));

      const userResponse = await axios.get(`${baseUrl}/getUser`, {
        headers: {
          Authorization: `Bearer ${response.data.token}`,
        },
      });

      const encryptedUserData = xorEncrypt(
        JSON.stringify(userResponse.data),
        "noman"
      );
      Cookies.set("%2515M%25", encryptedUserData, {
        expires: 7,
      });

      await dispatch({ type: "LOGIN", payload: userResponse.data });
      // setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      setError(err.response.data.message);
      console.error(err.response.data.message);
      setIsSubmitting(false);
      setErrOpen(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    setAuthToken(null);
    // setUser(null);
    Cookies.remove("%2515M%25");
    dispatch({ type: "LOGOUT", payload: null });
    setSuccessLogin(false);
    window.location.reload();
  };

  // let replicafetchData = localStorage.getItem("data");
  // let fetchData = JSON.parse(replicafetchData);

  const [bottomValue, setBottomValue] = React.useState(0);

  const formatNumbers = (number) => {
    return number?.toLocaleString("en-IN");
  };

  const availableBids = userCredit / 30000;

  return (
    <div>
      <Box className=" position-relative  ">
        {/* <Backdrop
          open={speedOpen}
          style={{ backgroundColor: "rgba(0, 0, 0, 0)", zIndex: 99 }}
          // onClick={handleSpeedToggle}
        /> */}
        {/* <SpeedDial
          ariaLabel=""
          sx={{ position: "fixed", bottom: 40, right: 30 }}
          icon={
            speedOpen ? (
              <div className=" d-flex gap-2  ">
                <CloseIcon /> Close
              </div>
            ) : (
              <div className=" d-flex gap-2  ">
                <QuestionAnswerOutlinedIcon /> Need Help?
              </div>
            )
          }
          onClick={handleSpeedToggle}
          open={speedOpen}
          className="SpeedDailBtn"
        ></SpeedDial> */}
        {speedOpen && (
          <div className="supportPopup  p-3">
            <div className="p-3">
              <h4 className="fs-2 text-white fw-bold  ">
                Hi 👋 <br />
                How can we help?
              </h4>
            </div>

            <div
              style={{
                borderRadius: 10,
                backgroundColor: "#28D146",
                cursor: "pointer ",
              }}
              className="p-3"
            >
              <div
                className=" d-flex align-items-center justify-content-between "
                onClick={() => setOpenWhatsapp(true)}
              >
                <div className=" messageBox">
                  <h6 className="mb-1">Whatsapp Chat</h6>
                  <p className="m-0">Easy Help, Anytime You Need!</p>
                </div>
                <div>
                  <WhatsAppIcon sx={{ color: "#ffffff", fontSize: 34 }} />
                </div>
              </div>
              {openWhatsapp && (
                <div className="mt-4 d-flex align-items-center justify-content-between  ">
                  <div className="w-100 me-2">
                    <input
                      className="form-control wa_input rounded-pill bg-white "
                      type="text"
                      placeholder="Message "
                      value={waMassage}
                      onChange={(e) => setWaMessage(e.target.value)}
                    />
                  </div>
                  <button
                    style={{
                      backgroundColor: "#075E54",
                      height: "45px",
                      width: "45px",
                      borderRadius: 100,
                    }}
                    className="btn"
                    onClick={openWhatsApp}
                  >
                    <SendIcon sx={{ color: "#ffffff" }} />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Box>

      {isSessionExpired && (
        <div
          className="text-center py-3"
          style={{ backgroundColor: "#0434bb" }}
        >
          <p className="mb-0 color-white">
            Your session has timed out. Please log in again.{" "}
            <a className="color-white fw-700" onClick={handleLogout} href="/">
              Refresh
            </a>
          </p>
        </div>
      )}
      {/* <InternetStatus isOnline={isOnline} onReload={handleReload} /> */}
      {!isOnline && (
        <div
          className="text-center py-1"
          style={{ backgroundColor: "#0434bb" }}
        >
          <p className="mb-0 color-white">
            You are currently offline. Please check your internet connection.
          </p>
        </div>
      )}
      <div className="bgSecondary">
        <div className="container d-flex justify-content-between align-items-center flex-nowrap px-2 m-auto py-1">
          <div className="d-flex justify-content-start align-items-center callUs text-white ">
            <h5 className="pe-2 m-0 fw-300">
              {/* <i className="fa-solid fa-location-dot me-2"></i> */}
              <img
                style={{ height: "16px" }}
                className="me-2 "
                src={PakistanFlag}
                alt="pakistan flag"
                srcSet=""
              />
              Pakistan
            </h5>
          </div>
          <div className="">
            <div className="d-flex justify-content-end preHeader_authBtns">
              {user && user ? (
                <>
                  <div className="userDetails">
                    <p className="m-0 dropdown-toggle fw-500 text-capitalize">
                      {/* <i className="IconStyle fa-regular fa-circle-user pe-2"></i> */}
                      Welcome! <b>{user?.name}</b>
                    </p>
                    <ul className="userDetails-subMenu ps-2 py-2">
                      {/* <li>
                        <Link className="w-100 d-block" to="/profile">
                          Profile Settings
                        </Link>
                      </li> */}
                      <li onClick={handleLogout}>
                        <Link className="w-100 d-block" to="">
                          <i className="fa-solid fa-arrow-right-from-bracket pe-2"></i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <p className="m-0 px-2 d-none d-md-block">|</p>
                </>
              ) : (
                " "
              )}
              {user && user ? (
                <>
                  <div className="userDetails">
                    {user?.role_id === 5 ? (
                      <p className="m-0 fw-600">Vehicle Inspector</p>
                    ) : (
                      <Link to="/become-a-member">
                        <p className="m-0 fw-600">Become a member</p>
                      </Link>
                    )}
                  </div>
                </>
              ) : null}
              {user && user ? (
                <>
                  <div className="userDetails">
                    {user?.role_id === 3 ? (
                      <Link to="/become-a-member">
                        <div className="d-flex ">
                          <img
                            src={CreditIcon}
                            className="membertick"
                            alt="credits"
                            srcSet="credits icon"
                          />
                          {userCredit === 0 ? (
                            <>
                              <p className="m-0 fw-600">
                                Credits: {formatNumbers(userCredit)} PKR
                              </p>
                              <Link to="/become-a-member">
                                <p className="m-0 fw-600">Buy Credits</p>
                              </Link>
                            </>
                          ) : (
                            <Tooltip
                              title={`Available Bids: ${availableBids}`}
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                              enterDelay={40}
                              leaveDelay={200}
                            >
                              <p className="m-0 fw-600">
                                Credits: {formatNumbers(userCredit)} PKR
                              </p>
                            </Tooltip>
                          )}
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : null}
              {!user ? (
                <></>
              ) : // <button
              //   className="btn p-0 me-2 fw-300"
              //   href="/"
              //   onClick={() => {
              //     handleLoginOpen();
              //     handlePostClose();
              //   }}
              // >
              //   <i className="IconStyle fa-regular fa-circle-user pe-2"></i>
              //   Login
              //   {/* / Signup */}
              // </button>
              // <button
              //   className="btn p-0 me-2 fw-300"
              //   href="/"
              //   onClick={handleLogout}
              // >
              //   <i className="fa-solid fa-arrow-right-from-bracket pe-2"></i>
              //   Logout
              // </button>
              null}
              {user && (
                <button
                  className="btn p-0 me-2 fw-300 d-md-none"
                  onClick={handleLogout}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket pe-2"></i>
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <nav className=" responseTab container m-auto flex-column navbar navbar-expand-lg navbar-light pb-2 py-0 pt-2 py-md-3">
        <div className="w-100 d-lg-none">
          <Link className="navbar-brand m-0" to="/">
            <img className="FW-logo" src={logo} alt="Famewheels" />
          </Link>
        </div>
        <div className="w-100 w-md-unset d-response">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link className="navbar-brand" to="/">
            <img
              className="FW-logo"
              src={logo}
              alt="FameWheels - Car Live Bidding and Cars for sale in Pakistan"
            />
          </Link>
          <div
            className="collapse navbar-collapse me-4"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto mt-3 mb-lg-0 fw-navbar justify-content-around  w-100">
              {user?.role_id === 1 || user?.role_id === 5 ? (
                <>
                  {/* <li className="nav-item">
                  <NavLink
                  exact
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <p>
                      Home
                    </p>
                  </NavLink>
                 
                </li> */}

                  {/* <li className="nav-item ">
                  <NavLink
                  exact
                    to="/inspector-form"
                    className="nav-link"
                    activeClassName="active"
                  >
                    <p>
                      Inspector Form
                    </p>
                  </NavLink>
                 
                </li> */}
                  <li className="nav-item dropdown serviceDrop-main">
                    <NavLink
                      to="/car-inspection"
                      className="nav-link "
                      activeClassName="active"
                    >
                      <p className="nav-link pad-0 dropdown-toggle m-0 serviceDrop">
                        Car Inspection
                      </p>
                    </NavLink>
                    <ul className="Services-subMenu ps-3 py-3">
                      <li>
                        <NavLink to="/car-inspection" activeClassName="active">
                          <img
                            src={InspectionIcon}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Book Inspection
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/offline-draft" activeClassName="active">
                          <img
                            src={InspectionIcon}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Offline Draft
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/offline-orders" activeClassName="active">
                          <img
                            src={InspectionIcon}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Offline Orders
                        </NavLink>
                      </li>

                      <li>
                        <Link to="/syncoffline">
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Offline Sync
                        </Link>
                      </li>

                      <li>
                        <NavLink
                          to="/inspection-orders"
                          activeClassName="active"
                        >
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          My Pending Orders{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/my-orders" activeClassName="active">
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcSet=""
                          />
                          My Inspections
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/inspection-history"
                          activeClassName="active"
                        >
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Inspection Reports
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/bidding-inspection-reports"
                          activeClassName="active"
                        >
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Bidding Inspection Reports
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/inspection-draft"
                          activeClassName="active"
                        >
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Inspection Draft
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/short-inspection-reports"
                          activeClassName="active"
                        >
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcset=""
                          />
                          Shor Inspection Report
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <NavLink
                    to="/car-inspection"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Car Inspection
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {/* bottom navigation */}
      <Box className="position-fixed bottomNavigation bottom-0 w-100 bg-white bottomNagivation_res ">
        <div className="bottomArea">
          <BottomNavigation
            showLabels
            value={bottomValue}
            onChange={(event, newValue) => {
              setBottomValue(newValue);
            }}
            className=" align-items-center Nav-Btns w-100 justify-content-between position-relative"
          >
            {/* {user?.role_id === 5 && (
              <Link to="/my-orders">
                <Button variant="text" className=" text-capitalize flex-column">
                  <DynamicFeedOutlinedIcon />
                  My Inspections
                </Button>
              </Link>
            )} */}
            {/* <Link to="/">
               <Button variant="text" className=" text-capitalize flex-column">
               <HomeRoundedIcon />
Home
               </Button>
             </Link> */}

            {user?.role_id === 5 && (
              <Link to="/inspection-orders">
                <Button variant="text" className=" text-capitalize flex-column">
                  <DynamicFeedOutlinedIcon />
                  My Pending Orders
                </Button>
              </Link>
            )}

            {user?.role_id === 5 && (
              <Link to={"/profile"}>
                <Button
                  variant="text"
                  className=" text-capitalize flex-column"
                  type="button"
                  onClick={handleLoginOpen}
                >
                  <AccountCircleIcon />
                  Account
                </Button>
              </Link>
            )}

            {user?.role_id === 5 && (
              <Button
                variant="text"
                className=" text-capitalize flex-column"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <MenuRoundedIcon />
                More
              </Button>
            )}

            <div
              className="offcanvas offcanvas-start w-75"
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <a className="navbar-brand" href="/">
                  <img
                    className="FW-drawer-logo"
                    src={logo}
                    alt="Fame Wheels"
                  />
                </a>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="drawerUser mb-3">
                  <div>
                    {user && user ? (
                      <>
                        <div className="userMobileDetails">
                          {user?.role_id === 3 ? (
                            <Link to="/become-a-member">
                              <div className="d-flex mt-2 bgFooter px-2 py-1 rounded">
                                <img
                                  src={CreditIcon}
                                  className="membertick"
                                  alt="credits"
                                  srcSet=""
                                />
                                <p className="m-0 fw-600">
                                  Credits: {formatNumbers(userCredit)} PKR
                                </p>
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="fwDrawer">
                  <ul className="position-relative ">
                    {/* <li>
                      <a href="/inspector-form">
                        <i className="fa-solid fa-house pe-2"></i>Inspector Form
                      </a>
                    </li> */}
                    {user && user?.role_id !== 5 ? (
                      <div>
                        <li>
                          <a href="/car-inspection">
                            <img
                              src={InspectionIcon}
                              className="membertick m-0 me-2"
                              alt=""
                              srcSet=""
                            />
                            Car Inspection
                          </a>
                        </li>
                      </div>
                    ) : user?.role_id === 5 ? (
                      <></>
                    ) : (
                      <div>
                        {user?.role_id === 1 || user?.role_id === 2 ? (
                          <li>
                            <a href="/become-a-member">
                              <i className="fa-solid fa-plus pe-2"></i>Become a
                              member
                            </a>
                          </li>
                        ) : user?.role_id === 3 ? (
                          <></>
                        ) : (
                          <li>
                            <a href="/become-a-member">
                              <i className="fa-solid fa-plus pe-2"></i>Become a
                              member
                            </a>
                          </li>
                        )}

                        <li>
                          <a href="/used-cars">
                            <i className="fa-solid fa-car pe-2"></i>Used Cars
                          </a>
                        </li>
                        <li>
                          <a href="/new-cars">
                            <i className="fa-solid fa-car pe-2"></i>New Cars
                          </a>
                        </li>
                        <li>
                          <a href="/car-inspection">
                            <img
                              src={InspectionIcon}
                              className="membertick m-0 me-2"
                              alt=""
                              srcSet=""
                            />
                            Car Inspection
                          </a>
                        </li>
                        <li>
                          <a href="/bidding">
                            <i className="fa-solid fa-arrow-trend-up pe-2"></i>
                            Live Bidding
                          </a>
                        </li>
                        <li>
                          <a href="/upcomming">
                            <i className="fa-brands fa-hive pe-2"></i>Upcoming
                            Bidding
                          </a>
                        </li>
                      </div>
                    )}
                    {user && user?.role_id === 5 && (
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to="/profile">
                          <img
                            src={InspectionOrders}
                            className="membertick m-0 me-2"
                            alt=""
                            srcSet=""
                          />
                          My Profile{" "}
                        </Link>
                      </li>
                    )}

                    {user && user?.role_id === 5 && (
                      <>
                        <li>
                          <a href="/car-inspection">
                            <img
                              src={InspectionIcon}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Book Inspections
                          </a>
                        </li>

                        <h6 className="fw-bold fs-5 text-danger">
                          Offline Inspections
                        </h6>

                        <li>
                          <Link to="/offline-orders">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Offline Orders
                          </Link>
                        </li>

                        <li>
                          <Link to="/offline-draft">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Offline Draft
                          </Link>
                        </li>

                        <li>
                          <Link to="/syncoffline">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Offline Sync
                          </Link>
                        </li>

                        <h6 className="fw-bold fs-5 text-danger">
                          Online Inspections
                        </h6>

                        <li>
                          <Link to="/inspection-orders">
                            <img
                              src={InspectionIcon}
                              className="membertick m-0 me-2"
                              alt=""
                              srcSet=""
                            />
                            New Orders
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link to="/my-orders">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcSet=""
                            />
                            My Pending Orders{" "}
                          </Link>
                        </li>
                      </>
                    )}
                    {user?.role_id === 5 || user?.role_id === 1 ? (
                      <>
                        <li>
                          <Link to="/inspection-draft">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            My Draft
                          </Link>
                        </li>

                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link to="/inspection-history">
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcSet=""
                            />
                            My Completed Orders
                          </Link>
                        </li>

                        <h6 className="fw-bold fs-5 text-danger">
                          Bidding Inspections
                        </h6>
                        <li>
                          <NavLink
                            to="/bidding-pending-orders"
                            activeClassName="active"
                          >
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Bidding Pending Orders
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/bidding-inspection-draft"
                            activeClassName="active"
                          >
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Bidding Inspection Draft
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/bidding-inspection-reports"
                            activeClassName="active"
                          >
                            <img
                              src={InspectionOrders}
                              className="membertick m-0 me-2"
                              alt=""
                              srcset=""
                            />
                            Bidding Inspection Reports
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                  </ul>
                  <div className="mt-auto">
                    {user && user ? (
                      <>
                        <div className="bgSecondary  px-3 py-3 rounded m-0 w-100">
                          <p className="m-0 fs-6 fw-700 color-white ">
                            <i className="fa-solid fa-user pe-2 fs-6"></i>
                            {user?.name}
                          </p>
                        </div>
                      </>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </div>
            </div>
          </BottomNavigation>
        </div>
      </Box>

      {/* Select Which Post Modal */}
      <Modal
        open={postOpen}
        onClose={handlePostClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="postModal_Main Fw-popups"
      >
        <Box className="lg-modal">
          <div className="modalBody_area  px-4 text-center">
            <h3 className="pb-2">Sell car in pakistan</h3>
            <h6 className="pb-2">
              Choose How <span>To Sell Your Car</span>
            </h6>
            <div className="row pt-4">
              <div className="col-lg-6 text-center">
                <div className="Modal_postSection p-4">
                  <h6 className="pb-3">
                    Post your Ad on<span> FameWheels </span>
                  </h6>
                  <img src={PostFW} alt="Post On Fame Wheels" />
                  <ul className="ps-5 pt-3">
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      Post your Ad for Free in 3 Easy Steps
                    </li>
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      Get Genuine offers from Verified Buyers
                    </li>
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      Sell your car Fast at the Best Price
                    </li>
                    <li className="postNote">
                      * You can post only two free Ads per month
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <div className="Modal_postSection p-4">
                  <h6 className="pb-3">
                    Try Sell To<span> FameWheels </span>
                  </h6>
                  <img src={SellFW} alt="Post On Fame Wheels" />
                  <ul className="ps-5 pt-3">
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      Dedicated Sales Expert to Sell your Car
                    </li>
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      We Bargain for you and share the Best Offer
                    </li>
                    <li>
                      <span>
                        <i className="fa-regular fa-circle-check"></i>
                      </span>
                      We ensure Safe & Secure Transaction
                    </li>
                    <li className="postNote">
                      * Service available only in Karachi, Lahore, Islamabad and
                      Rawalpindi
                    </li>
                  </ul>
                  {user && user ? (
                    <Link to="/post-ad?Sell-to-fameWheels">
                      <button
                        className="btn fw-primary ModalPostBtn"
                        onClick={() => {
                          handlePostClose();
                        }}
                      >
                        Sell To FameWheels
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="btn fw-primary ModalPostBtn"
                      onClick={() => {
                        handleLoginOpen();
                        handlePostClose();
                      }}
                    >
                      Sell To FameWheels
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center pt-3">
              <p className="privacyText">
                * By continuing, you are agreeing to the
                <a href="/">terms of service</a> and
                <a href="/">privacy policy</a>
              </p>
            </div>
          </div>
        </Box>
      </Modal>

      {/* login popup */}
      <Snackbar
        open={errOpen}
        autoHideDuration={6000}
        onClose={handleBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleBarClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      {/* <Modal
        open={loginOpen}
        onClose={handleLoginClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal p-3 p-md-4">
          <div className="modalBody_area  px-2 ">
            <h3 className="pb-2 text-center">Login</h3>
            <h6 className="pb-2 text-center">
              Connect with <span>Buyers</span>
            </h6>
            <p className="pb-2 connect_para text-center">
              You can connect with thousands of buyers and quick search
            </p>
            <div>
              <form onSubmit={handleLogin} className="row px-3">
                <div className="col-md-12 login_inputStyle mb-3">
                 
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Type your Email Address"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      required
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12 login_inputStyle mb-3">
         
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder="Type your password"
                      aria-label="password"
                      aria-describedby="basic-addon1"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="login_forgot text-start">
                  <Link
                    onClick={() => {
                      handleLoginClose();
                    }}
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="col-12 p-0">
                  <button
                    type="submit"
                    className="btn mt-3 fw-btn model_loginBTn w-100"
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress size="16px" sx={{ color: "#fff" }} />
                      </>
                    ) : successLogin ? (
                      <img
                        className="successAnim"
                        src={SuccessTick}
                        alt="success"
                        srcSet=""
                      />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className="text-center pt-3">
              <p className="have_account">
                Don’t have an account?{" "}
                <button
                  onClick={() => {
                    handleSignupOpen();
                    handleLoginClose();
                  }}
                >
                  Signup
                </button>
              </p>
            </div>
            <div className="text-center pt-3">
              <p className="privacyText">
                By continuing, you are agreeing to the{" "}
                <a href="/">terms of service</a> and{" "}
                <a href="/">privacy policy</a>
              </p>
            </div>
          </div>
        </Box>
      </Modal> */}

      {/* Sign up popup */}
      {/* <Modal
        open={signupOpen}
        onClose={handleSignupClose}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal p-3 p-md-4">
          <div className="modalBody_area  px-2 ">
            <h3 className="pb-2 text-center">Sign Up</h3>
            <h6 className="pb-2 text-center">
              Connect with <span>Buyers</span>
            </h6>
            <p className="pb-2 connect_para text-center">
              You can connect with thousands of buyers and quick search
            </p>

            <div>
              <form onSubmit={handleRegister} className="row px-3">
                <div className="col-md-12 login_inputStyle mb-3">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-user"></i>
                    </span>
                    <input
                      type="text"
                      id="username"
                      name="userName"
                      className="form-control"
                      placeholder="Full Name"
                      aria-label="Full Name"
                      aria-describedby="uidnote"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                </div>

                <div className="col-md-12 login_inputStyle mb-3">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="email"
                      aria-describedby="uidnote"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>

          
                <div className="col-md-12 login_inputStyle mb-3">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                  </div>
                </div>
                <div
                  className={`col-md-12 login_inputStyle ${
                    passwordMatchError ? "mb-0" : "mb-3"
                  } `}
                >
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      id="memberPassword"
                      name=""
                      placeholder="Confirm Password"
                      required
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>
                </div>
                {passwordMatchError && (
                  <p className="error-message p-0">{passwordMatchError}</p>
                )}

                <div className="col-md-12 login_inputStyle mb-3">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-phone"></i>
                    </span>
                    <InputMask
                      mask="99999999999"
                      maskChar={null}
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder="Phone No. (03xxxxxxxxx)"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="login_forgot text-start">
                  <Link
                    onClick={() => {
                      handleSignupClose();
                    }}
                    to="/"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="col-12 px-0">
                  <button
                    type="submit"
                    className="btn mt-3 fw-btn model_loginBTn w-100"
                    disabled={
                      password !== confirmPassword ||
                      name === "" ||
                      email === "" ||
                      phone === ""
                    }
                  >
                    {isSignSubmitting ? (
                      <CircularProgress size="16px" sx={{ color: "#fff" }} />
                    ) : successSignup ? (
                      <img
                        className="successAnim"
                        src={SuccessTick}
                        alt="success"
                        srcSet=""
                      />
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className="text-center pt-3">
              <p className="have_account">
                Already have an account?{" "}
                <button
                  onClick={() => {
                    handleLoginOpen();
                    handleSignupClose();
                  }}
                >
                  Login
                </button>
              </p>
            </div>
            <div className="text-center pt-3">
              <p className="privacyText">
                By continuing, you are agreeing to the{" "}
                <a href="/">terms of service</a> and{" "}
                <a href="/">privacy policy</a>
              </p>
            </div>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
}
