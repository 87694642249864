import React, { useEffect } from "react";
import Inspectionorders from "../components/vehicleInspection/admin/inspectionOrders.jsx";
import { AuthContext } from "../context/AuthContext.js";
import { useHistory } from "react-router-dom";

export default function AdminInspectionOrders() {
  const { user } = React.useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    document.title = "Inspection Orders | FameWheels";
    if (!user) {
      history.push("/");
    }
  }, []);
  return (
    <>
      <Inspectionorders />
    </>
  );
}
