import React, { useRef, useEffect, useState } from "react";

import "../../Styles/style.css";
import Button from "@mui/material/Button";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { Form } from "react-bootstrap";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import s3 from "../../../config/s3Config";

const Step4 = ({ onSuccess, previouse, user, userData, step8, setStep8 }) => {
  const token = localStorage.getItem("token");

  const history = useHistory();

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [successIsOpen, setSuccessIsOpen] = useState(false);
  const [drafts, setDrafts] = useState([]);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [images, setImages] = React.useState({});
  const [isTestDrive, setIsTestDrive] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);

  const [stepImages, setStepImages] = useState(null);

  const [isSycc, setIsSync] = useState(null);

  const [payment, setPayment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [securityDeposit, setsSecurityDeposit] = useState(null);
  const [paymentToken, setsPaymentToken] = useState("");
  const [paymentStatus, setsPaymentStatus] = useState("");
  const [openPayment, setOpenPayment] = useState(false);
  const [isAuction, setIsAuction] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");
  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 1500);
  }, [syncable]);

  const inspectionId = extractTokenFromUrl(url, "inspection");

  useEffect(() => {
    const getInpectionId = async () => {
      try {
        const response = await axios.get(`${baseUrl}/inspectionrequestdetail`, {
          params: {
            inspection_id: inspectionId,
          },
        });

        setsPaymentStatus(response?.data?.vehicledetail?.payment_status);
        setIsAuction(response?.data?.vehicledetail?.is_auction);
      } catch (error) {
        console.error(error);
      }
    };

    getInpectionId();
  }, [inspectionId]);

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  const handleDeleteAllImages = async () => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id

      for (const category in images) {
        if (images.hasOwnProperty(category)) {
          for (const image of images[category]) {
            await store.delete(image.id);
            // console.log(image)
          }
        }
      }
    } catch (error) {
      console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      // await getLocalImages();
    }
  };

  const deleteDefectByStepToken = async () => {
    try {
      const db = await openDB("defects-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("defects")) {
            const objectStore = db.createObjectStore("defects", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      const transaction = db.transaction("defects", "readonly");

      const objectStore = transaction.objectStore("defects");

      const stored = await objectStore.getAll();

      const tx = db.transaction("defects", "readwrite");
      const store = tx.objectStore("defects");

      // // Delete the image with the specified id
      // await store.delete(defectId?.id);

      if (stored) {
        const filteredDefects = stored.filter(
          (defect) => defect.initial_token === StepToken
        );

        for (const defect of filteredDefects) {
          await store.delete(defect.id);
        }
      }
    } catch (error) {
      console.error("Error retrieving defects from IndexedDB:", error);
    }
  };

  useEffect(() => {
    const parsedData = JSON.parse(localStorage.getItem(`${StepToken}_eighth`));

    setIsTestDrive(parsedData?.ii_testdrive);
  }, []);

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          // const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          // const formData = new FormData();

          // // Append common form data fields
          // formData.append("iinitial_token", StepToken);
          // formData.append("inspectionstep_id", 8);

          // // Append each image to formData
          // formData.append(`file[]`, image.image);
          // formData.append(`image_name`, image.name); // Assuming name is an array

          // //console.log("FormData:", formData.get("image_name"));

          // const response = await axios.post(uploadEndpoint, formData, {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //     "Content-Type": "multipart/form-data",
          //   },
          // });

          const s3Key = `public/inspection/${StepToken}/${image?.name}/${image?.image?.name}`;
  
          const params = {
            Bucket: 'famewheels',
            Key: s3Key,
            Body: image.image,
            // ACL: 'public-read', // Or the appropriate ACL
            ContentType: image.image.type,
          };
  
          const response = await s3.upload(params).promise();

          const url = response.Key;
 
           const ImageName = url.substring(url.lastIndexOf('/') + 1);
 
           const formData = new FormData();
           formData.append("image_name", image?.name);
           formData.append("iinitial_token", StepToken);
           formData.append("inspectionstep_id", 8);
           formData.append(`file`, ImageName);
 
           const responseApi = await axios.post(
             `${baseUrl}/saveinspectionimage`,
             formData,
             {
               headers: {
                 Authorization: `Bearer ${token}`,
                 "Content-Type": "multipart/form-data",
               },
             }
           );
          //console.log("API Response:", response.data);

          //  await handleDeleteImage(image.id,image.id)
        } catch (error) {
          //console.log(error)
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
    // setGalleryImages(images);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 8);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = filteredImagesData.filter(
        (images) => images.inspection_step === 7
      );

      setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    getLocalImages();
  }, []);

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_eighth`);
      if (formData) {
        const newData = JSON.parse(formData);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    // Open the IndexedDB database
    const db = await openDB("my-database", 1, {
      upgrade(db) {
        // Check if the object store exists before creating it
        if (!db.objectStoreNames.contains("images")) {
          // Create an object store for storing images
          db.createObjectStore("images", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });

    // Iterate over each file
    for (const file of files) {
      const options = {
        maxSizeMB: 0.06,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      //console.log("Compressed file:", compressedFile);

      const compressedImageFile = new File([compressedFile], file.name, {
        type: file.type,
      });

      const imageData = {
        name: fieldName,
        image: compressedImageFile,
        token: StepToken,
        inspection_step: 7,
      };

      try {
        // Store the image data in IndexedDB
        const tx = db.transaction("images", "readwrite");
        const store = tx.objectStore("images");
        await store.add(imageData);
        await tx.done;
        //console.log("Image uploaded to IndexedDB successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Retrieve the stored images from IndexedDB
    await getLocalImages();
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "8";
            obj.token = StepToken;
          }
        });

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_eighth`, serializedFormData);

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        history.push("/syncoffline");
        // onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 8");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const CreateUser = async () => {
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("user_id", userData?.id);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse.iinitial_id
        );
        params.data.append("iinitial_token", StepToken);
        // params.data.append("is_edit", 0);
        try {
          const response = await axios.post(
            `${baseUrl}/saveinteriorinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (Array.isArray(drafts)) {
            const draftFiltered = drafts.filter((e) => {
              //console.log("Comparison:", e.inspection_id, parseInt(inspectionId));
              return e.inspection_id !== parseInt(inspectionId);
            });

            //console.log("draftFiltered filtered ====== ", draftFiltered);
            // Update localStorage with filtered data
            localStorage.setItem(
              "inspection_draft",
              JSON.stringify(draftFiltered)
            );

            await handleDeleteAllImages();

            await deleteDefectByStepToken();

            localStorage.removeItem(`${StepToken}_first`);
            localStorage.removeItem(`${StepToken}_second`);
            localStorage.removeItem(`${StepToken}_third`);
            localStorage.removeItem(`${StepToken}_fourth`);
            localStorage.removeItem(`${StepToken}_fifth`);
            localStorage.removeItem(`${StepToken}_sixth`);
            localStorage.removeItem(`${StepToken}_seventh`);
            localStorage.removeItem(`${StepToken}_eighth`);
            localStorage.removeItem(`${StepToken}_userData`);

            if (isAuction !== 1) {
              if (paymentStatus !== "paid") {
                setOpenPayment(true);
              } else {
                setSuccessIsOpen(true);

                setTimeout(() => {
                  setSuccessIsOpen(false);
                  window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
                  history.push(`/inspection-history`);
                }, 4000);
              }
            } else {
              setSuccessIsOpen(true);

              setTimeout(() => {
                setSuccessIsOpen(false);
                window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
                history.push(`/bidding-inspection-reports`);
              }, 4000);
            }
          } else {
            //console.log("Drafts is undefined.");
          }

          if (response?.data) {
            setSuccessOpen(true);
            setSuccess("Step 8 Completed");
            // onSuccess();
          }

          // localStorage.removeItem(`${StepToken}_seventh`)

          //console.log("====================================");
          //console.log(response);
          setIsSubmitting(false);
          //console.log("====================================");
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 8 Submit Failed!`);
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setIsSubmitting(true);
        setIsSubmittingFields(true);
        setPending(true);
        setPendingData("Completing Step 8");
        await CreateUser();
      } catch (e) {
        console.error(e);
        // Handle error if needed
      } finally {
        setPending(false);
        setIsSubmitting(false);
        setIsSubmittingFields(false);
      }
    }
  };

  const handleSave = async () => {
    try {
      await postImages();
      await handleSaveFields();
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 500);
    }
  }, [isSycc]);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const formData = new FormData();

    formData.append("payment_status", payment);
    formData.append("payment_method", paymentMethod);
    formData.append("security_deposit", securityDeposit);
    formData.append("payment_token", paymentToken);
    formData.append("inspection_id", inspectionId);

    try {
      const response = await axios.post(
        `${baseUrl}/inspection-payment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOpenPayment(false);
      setSuccessIsOpen(true);

      setTimeout(() => {
        setSuccessIsOpen(false);
        window.open(`https://inspectionreport.famewheels.com/inspection-report/${StepToken}`);
        history.push(`/inspection-history`);
      }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCarPart = (part, value) => {
    setStep8((prevState) => {
      const updatedStep8 = {
        ...prevState,
        [part]: value,
      };
      localStorage.setItem(
        `${StepToken}_eighth2`,
        JSON.stringify(updatedStep8)
      );
      return updatedStep8;
    });
  };

  useEffect(() => {
    const formData = localStorage.getItem(`${StepToken}_eighth`);
    if (formData) {
      const parsedData = JSON.parse(formData);
      setStep8((prev) => ({
        ...prev,
        testDrive: parsedData.ii_testdrive,
      }));
    }
  }, []);

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <Modal
        open={openPayment}
        onClose={() => {
          setOpenPayment(false);
        }}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="md-modal position-relative p-3 p-md-4">
          <form
            className="w-100 p-3 mt-4 rounded-4"
            onSubmit={handlePaymentSubmit}
          >
            <h4 className="textSecondary">Payment Information</h4>

            <hr className="text-black" />

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label
                  for="payment_status"
                  className="form-label text-black btntextSize"
                >
                  Payment Recieved
                </label>
                <select
                  name="payment_status"
                  className="form-select btntextSize"
                  id="payment_status"
                  aria-label="Default select example"
                  required
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <option selected value="">
                    Select Payment
                  </option>
                  <option value="paid">Paid</option>
                  <option value="un_paid">Un Paid</option>
                </select>
              </div>

              {payment === "paid" && (
                <>
                  <div className="col-lg-6 mb-3">
                    <label
                      for="security_deposit"
                      className="form-label text-black btntextSize"
                    >
                      Inspection Amount
                    </label>
                    <select
                      name="security_deposit"
                      className="form-select btntextSize"
                      id="security_deposit"
                      aria-label="Default select example"
                      required
                      value={securityDeposit}
                      onChange={(e) => setsSecurityDeposit(e.target.value)}
                    >
                      <option selected value="">
                        Select Amount
                      </option>
                      <option value={2000}>2000</option>
                      <option value={4000}>4000</option>
                      <option value={6000}>6000</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label
                      for="payment_method"
                      className="form-label text-black btntextSize"
                    >
                      Payment Mehtod
                    </label>
                    <select
                      name="payment_method"
                      className="form-select btntextSize"
                      id="payment_method"
                      aria-label="Default select example"
                      required
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option selected value="">
                        Select Payment Mehtod
                      </option>
                      <option value={"cash"}>Cash</option>
                      <option value={"online"}>Online</option>
                    </select>
                  </div>

                  {paymentMethod === "online" && (
                    <div className="col-lg-6 mb-3">
                      <label
                        for="payment_token"
                        className="form-label text-black btntextSize"
                      >
                        Transection Id
                      </label>
                      <input
                        name="payment_token"
                        type="text"
                        className="form-control p-2 w-100 btntextSize"
                        id="payment_token"
                        aria-describedby="titleHelp"
                        required
                        placeholder="Transaction Id"
                        value={paymentToken}
                        onChange={(e) => setsPaymentToken(e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <Button
              type="submit"
              className="color-white LegendIcon bg p-2 text-capitalize"
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="ii_testdrive"
                    className="form-label btntextSize text-black"
                  >
                    Test Drive
                  </label>
                  <div className="input-group">
                    <select
                      name="ii_testdrive"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step8.testDrive}
                      onChange={(e) => {
                        setIsTestDrive(e.target.value);
                        updateCarPart("testDrive", e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ii_testdriveimage &&
                      images.ii_testdriveimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.ii_testdriveimage);
                            setImageId("ii_testdriveimage");
                            getImages("ii_testdriveimage");
                          }}
                        >
                          {images.ii_testdriveimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ii_testdriveimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ii_testdriveimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ii_testdriveimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ii_testdriveimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {step8.testDrive === "Yes" && (
                <>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_acoperation"
                        className="form-label btntextSize text-black"
                      >
                        AC Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_acoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.acOperationalDriving}
                          onChange={(e) =>
                            updateCarPart(
                              "acOperationalDriving",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Aplicable">Not Aplicable</option>
                          <option value="Perfect">Perfect</option>
                          <option value="Unsatisfactory">Unsatisfactory</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_acoperationimage &&
                          images.ii_acoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_acoperationimage);
                                setImageId("ii_acoperationimage");
                                getImages("ii_acoperationimage");
                              }}
                            >
                              {images.ii_acoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_acoperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_acoperationimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_acoperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_acoperationimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_heateroperation"
                        className="form-label btntextSize text-black"
                      >
                        Heater Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_heateroperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.heaterOperation}
                          onChange={(e) =>
                            updateCarPart("heaterOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Aplicable">Not Aplicable</option>
                          <option value="Perfect">Perfect</option>
                          <option value="Unsatisfactory">Unsatisfactory</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_heateroperationimage &&
                          images.ii_heateroperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_heateroperationimage
                                );
                                setImageId("ii_heateroperationimage");
                                getImages("ii_heateroperationimage");
                              }}
                            >
                              {images.ii_heateroperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_heateroperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_heateroperationimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_heateroperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_heateroperationimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_enginepick"
                        className="form-label btntextSize text-black"
                      >
                        Engine Pick
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_enginepick"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.enginePick}
                          onChange={(e) =>
                            updateCarPart("enginePick", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="ok">Ok</option>
                          <option value="Not Ok">Not Ok</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_enginepickimage &&
                          images.ii_enginepickimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_enginepickimage);
                                setImageId("ii_enginepickimage");
                                getImages("ii_enginepickimage");
                              }}
                            >
                              {images.ii_enginepickimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_enginepickimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_enginepickimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_enginepickimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_enginepickimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_clutchoperation"
                        className="form-label btntextSize text-black"
                      >
                        Clutch Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_clutchoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.clutchOperation}
                          onChange={(e) =>
                            updateCarPart("clutchOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Hand or Free">Hand or Free</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_clutchoperationimage &&
                          images.ii_clutchoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_clutchoperationimage
                                );
                                setImageId("ii_clutchoperationimage");
                                getImages("ii_clutchoperationimage");
                              }}
                            >
                              {images.ii_clutchoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_clutchoperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_clutchoperationimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_clutchoperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_clutchoperationimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_gearshiftingmanual"
                        className="form-label btntextSize text-black"
                      >
                        Gear Shifting (Manual)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_gearshiftingmanual"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.gearShiftingManual}
                          onChange={(e) =>
                            updateCarPart("gearShiftingManual", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Hard">Hard</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_gearshiftingimage &&
                          images.ii_gearshiftingimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_gearshiftingimage);
                                setImageId("ii_gearshiftingimage");
                                getImages("ii_gearshiftingimage");
                              }}
                            >
                              {images.ii_gearshiftingimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_gearshiftingimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_gearshiftingimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_gearshiftingimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_gearshiftingimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_gearshiftingauto"
                        className="form-label btntextSize text-black"
                      >
                        Gear Shifting (Automatic)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_gearshiftingauto"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.gearShiftingAutomatic}
                          onChange={(e) =>
                            updateCarPart(
                              "gearShiftingAutomatic",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Jerk">Jerk</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_gearshiftingautoimage &&
                          images.ii_gearshiftingautoimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_gearshiftingautoimage
                                );
                                setImageId("ii_gearshiftingautoimage");
                                getImages("ii_gearshiftingautoimage");
                              }}
                            >
                              {images.ii_gearshiftingautoimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_gearshiftingautoimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_gearshiftingautoimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_gearshiftingautoimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_gearshiftingautoimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_4X4shifting"
                        className="form-label btntextSize text-black"
                      >
                        4X4 Shifting
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_4X4shifting"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.shifting4x4}
                          onChange={(e) =>
                            updateCarPart("shifting4x4", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Working">Working</option>
                          <option value="Not Working">Not Working</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_4x4shiftingimage &&
                          images.ii_4x4shiftingimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_4x4shiftingimage);
                                setImageId("ii_4x4shiftingimage");
                                getImages("ii_4x4shiftingimage");
                              }}
                            >
                              {images.ii_4x4shiftingimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_4x4shiftingimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_4x4shiftingimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_4x4shiftingimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_4x4shiftingimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_driveshaft_noise"
                        className="form-label btntextSize text-black"
                      >
                        Drive Shaft Noise
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_driveshaft_noise"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.driveShaftNoise}
                          onChange={(e) =>
                            updateCarPart("driveShaftNoise", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>
                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_driveshaftnoiceimage &&
                          images.ii_driveshaftnoiceimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_driveshaftnoiceimage
                                );
                                setImageId("ii_driveshaftnoiceimage");
                                getImages("ii_driveshaftnoiceimage");
                              }}
                            >
                              {images.ii_driveshaftnoiceimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_driveshaftnoiceimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_driveshaftnoiceimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_driveshaftnoiceimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_driveshaftnoiceimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_frontpropellershaft"
                        className="form-label btntextSize text-black"
                      >
                        Front Propeller Shaft
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_frontpropellershaft"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.frontPropellerShaft}
                          onChange={(e) =>
                            updateCarPart("frontPropellerShaft", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                          <option value="Removed from car">
                            Removed from car
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_frontpropellershaftimage &&
                          images.ii_frontpropellershaftimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_frontpropellershaftimage
                                );
                                setImageId("ii_frontpropellershaftimage");
                                getImages("ii_frontpropellershaftimage");
                              }}
                            >
                              {images.ii_frontpropellershaftimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_frontpropellershaftimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_frontpropellershaftimage"
                                )
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_frontpropellershaftimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_frontpropellershaftimage"
                                )
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_rear_propeller_shaft"
                        className="form-label btntextSize text-black"
                      >
                        Rear Propeller Shaft
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_rear_propeller_shaft"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.rearPropellerShaft}
                          onChange={(e) =>
                            updateCarPart("rearPropellerShaft", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                          <option value="Removed from car">
                            Removed from car
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_rear_propeller_shaftimage &&
                          images.ii_rear_propeller_shaftimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_rear_propeller_shaftimage
                                );
                                setImageId("ii_rear_propeller_shaftimage");
                                getImages("ii_rear_propeller_shaftimage");
                              }}
                            >
                              {images.ii_rear_propeller_shaftimage.length}{" "}
                              photos <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_rear_propeller_shaftimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_rear_propeller_shaftimage"
                                )
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_rear_propeller_shaftimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_rear_propeller_shaftimage"
                                )
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_differentialnoise"
                        className="form-label btntextSize text-black"
                      >
                        Differential Noise
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_differentialnoise"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.differentialNoise}
                          onChange={(e) =>
                            updateCarPart("differentialNoise", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_differentialnoiseimage &&
                          images.ii_differentialnoiseimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_differentialnoiseimage
                                );
                                setImageId("ii_differentialnoiseimage");
                                getImages("ii_differentialnoiseimage");
                              }}
                            >
                              {images.ii_differentialnoiseimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_differentialnoiseimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_differentialnoiseimage"
                                )
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_differentialnoiseimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_differentialnoiseimage"
                                )
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_brakepedaloperation"
                        className="form-label btntextSize text-black"
                      >
                        Brake Pedal Operation
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_brakepedaloperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.brakePedalOperation}
                          onChange={(e) =>
                            updateCarPart("brakePedalOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Timely Response">
                            Timely Response
                          </option>
                          <option value="Untimely Response">
                            Untimely Response
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_brakepedaloperationimage &&
                          images.ii_brakepedaloperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_brakepedaloperationimage
                                );
                                setImageId("ii_brakepedaloperationimage");
                                getImages("ii_brakepedaloperationimage");
                              }}
                            >
                              {images.ii_brakepedaloperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_brakepedaloperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_brakepedaloperationimage"
                                )
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_brakepedaloperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_brakepedaloperationimage"
                                )
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field5"
                        className="form-label btntextSize text-black"
                      >
                        ABS Operation
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_absoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.absOperation}
                          onChange={(e) =>
                            updateCarPart("absOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Not Applicable">Not Applicable</option>
                          <option value="Timely Response">
                            Timely Response
                          </option>
                          <option value="Untimely Response">
                            Untimely Response
                          </option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_absoperationimage &&
                          images.ii_absoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_absoperationimage);
                                setImageId("ii_absoperationimage");
                                getImages("ii_absoperationimage");
                              }}
                            >
                              {images.ii_absoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_absoperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_absoperationimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_absoperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_absoperationimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_steringoperation"
                        className="form-label btntextSize text-black"
                      >
                        Steering Operation (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_steringoperation"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.steeringOperation}
                          onChange={(e) =>
                            updateCarPart("steeringOperation", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Smooth">Smooth</option>
                          <option value="Rough">Rough</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_steeringoperationimage &&
                          images.ii_steeringoperationimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_steeringoperationimage
                                );
                                setImageId("ii_steeringoperationimage");
                                getImages("ii_steeringoperationimage");
                              }}
                            >
                              {images.ii_steeringoperationimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_steeringoperationimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_steeringoperationimage"
                                )
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_steeringoperationimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  "ii_steeringoperationimage"
                                )
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_steeringwheelplay"
                    className="form-label btntextSize text-black"
                  >
                    Steering Wheel Play
                  </label>
                  <div className="input-group">
                    <select
                      name="ii_steeringwheelplay"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step8.steeringWheelPlay}
                      onChange={(e) =>
                        updateCarPart("steeringWheelPlay", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Play">Play</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.ii_steeringwheelplayimage &&
                      images.ii_steeringwheelplayimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.ii_steeringwheelplayimage
                            );
                            setImageId("ii_steeringwheelplayimage");
                            getImages("ii_steeringwheelplayimage");
                          }}
                        >
                          {images.ii_steeringwheelplayimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ii_steeringwheelplayimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ii_steeringwheelplayimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="ii_steeringwheelplayimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "ii_steeringwheelplayimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              {step8.testDrive === "Yes" && (
                <>
                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_steeringwheelalignment"
                        className="form-label btntextSize text-black"
                      >
                        Steering Wheel Alignment (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_steeringwheelalignment"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.steeringWheelAlignment}
                          onChange={(e) =>
                            updateCarPart(
                              "steeringWheelAlignment",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Centered">Centered</option>
                          <option value="Not Centered">Not Centered</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_steeringalignimage &&
                          images.ii_steeringalignimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_steeringalignimage
                                );
                                setImageId("ii_steeringalignimage");
                                getImages("ii_steeringalignimage");
                              }}
                            >
                              {images.ii_steeringalignimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_steeringalignimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_steeringalignimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_steeringalignimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_steeringalignimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field6"
                        className="form-label btntextSize text-black"
                      >
                        Front Suspension (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_frontsuspension"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.frontSuspension}
                          onChange={(e) =>
                            updateCarPart("frontSuspension", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_frontsuspensionimage &&
                          images.ii_frontsuspensionimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_frontsuspensionimage
                                );
                                setImageId("ii_frontsuspensionimage");
                                getImages("ii_frontsuspensionimage");
                              }}
                            >
                              {images.ii_frontsuspensionimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_frontsuspensionimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_frontsuspensionimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_frontsuspensionimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_frontsuspensionimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="field7"
                        className="form-label btntextSize text-black"
                      >
                        Rear Suspension (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_rearsuspension"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.rearSuspension}
                          onChange={(e) =>
                            updateCarPart("rearSuspension", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="No Noise">No Noise</option>
                          <option value="Noise">Noise</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_rearssupensionimage &&
                          images.ii_rearssupensionimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_rearssupensionimage
                                );
                                setImageId("ii_rearssupensionimage");
                                getImages("ii_rearssupensionimage");
                              }}
                            >
                              {images.ii_rearssupensionimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_rearssupensionimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_rearssupensionimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_rearssupensionimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_rearssupensionimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_speedometer"
                        className="form-label btntextSize text-black"
                      >
                        Speedometer (While Driving)
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_speedometer"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.speedoMeter}
                          onChange={(e) =>
                            updateCarPart("speedoMeter", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Working">Working</option>
                          <option value="Not Working">Not Working</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_speedometerimage &&
                          images.ii_speedometerimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(images.ii_speedometerimage);
                                setImageId("ii_speedometerimage");
                                getImages("ii_speedometerimage");
                              }}
                            >
                              {images.ii_speedometerimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_speedometerimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_speedometerimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_speedometerimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_speedometerimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 mb-3">
                    <div>
                      <label
                        for="ii_testdrivedoneby"
                        className="form-label btntextSize text-black"
                      >
                        Test Drive Done By
                      </label>
                      <div className="input-group">
                        <select
                          name="ii_testdrivedoneby"
                          className="form-select btntextSize"
                          id="vehicleCategory"
                          aria-label="Default select example"
                          required
                          value={step8.testDriveBy}
                          onChange={(e) =>
                            updateCarPart("testDriveBy", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="Seller">Seller</option>
                          <option value="Buyer">Buyer</option>
                          <option value="Technician">Technician</option>
                        </select>
                      </div>

                      <span
                        className="d-flex justify-content-between align-items-center"
                        id="basic-addon2"
                      >
                        <span>
                          {images?.ii_testdrivedonebyimage &&
                          images.ii_testdrivedonebyimage.length > 0 ? (
                            <span
                              className="color-secondary fw-600 p-0"
                              onClick={() => {
                                handleImageGallery(
                                  images.ii_testdrivedonebyimage
                                );
                                setImageId("ii_testdrivedonebyimage");
                                getImages("ii_testdrivedonebyimage");
                              }}
                            >
                              {images.ii_testdrivedonebyimage.length} photos{" "}
                              <ImageIcon color="error" />
                            </span>
                          ) : (
                            <span className="color-secondary fw-600 p-0">
                              0 photos <ImageIcon color="error" />
                            </span>
                          )}
                        </span>

                        <div className="d-flex justify-content-center align-items-center flex-column">
                          <Button
                            variant="text"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-camera"></i> Camera
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_testdrivedonebyimage"
                              accept="image/**"
                              capture="user"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_testdrivedonebyimage")
                              }
                            />
                          </Button>

                          <Button
                            variant="contained"
                            component="label"
                            className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                          >
                            <span className="text-capitalize btntextSize">
                              <i className="fa-solid fa-image"></i> Gallery
                            </span>
                            <input
                              className="form-control visually-hidden"
                              id="ii_testdrivedonebyimage"
                              accept=".jpg,.jpeg,.png"
                              type="file"
                              onChange={(e) =>
                                handleImageChange(e, "ii_testdrivedonebyimage")
                              }
                            />
                          </Button>
                        </div>
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 mt-3">
                <label
                  for="iin_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="iin_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center d-flex justify-content-center align-items-center gap-2 pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Only Save
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    disabled={
                      pending === true ||
                      isSubmitting === true ||
                      isSubmittingFields === true
                        ? true
                        : false
                    }
                    className=" px-5 color-white"
                    variant="contained"
                    onClick={handleSave}
                  >
                    Save & Finish
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Step4;
