import * as React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import VehicleInspection from "./pages/vehicle-inspection.jsx";
import UserInspectionOrders from "./pages/my-orders";
import AdminInspectionOrders from "./pages/inspection-orders";
import Completedorders from "./components/vehicleInspection/admin/completedOrders";
import MyProfile from "./pages/profile";
import Header from "./components/header";
import ScrollToTop from "./scrollToTop";
import InspectorForm from "./pages/inspector-form";
import InspectorReport from "./pages/inspection-reprt";
import InspectionHistory from "./pages/inspection-history";
import ForgotPassword from "./pages/forgot-password";
import Cookies from "js-cookie";
import { useAuthContext } from "./hooks/useAuthContext";
import ResetPassword from "./components/forgot-password/reset-password";
import PendingInspections from "./components/ads/pending-inspections.jsx";
import Homepage from "./pages/homepage";
import BiddingInspectionHistoryBody from "./components/ads/bidding-inspection-history.jsx";
import BiddingPendingOrders from "./components/vehicleInspection/user/biddingPendingOrders.jsx";
import BiddingInspectionDraft from "./components/ads/bidding-inspection-draft.jsx";
import baseUrl from "./api/ipconfig.js";
import axios from "axios";
import AdminOfflineOrders from "./pages/offlineOrders.jsx";
import OfflineOrders from "./components/offlineOrders/OfflineOrders.jsx";
import InspectorFormOnline from "./pages/inspector-form-online.jsx";
import OfflineDraft from "./components/offlineOrders/offlineDraft.jsx";
import OfflineSync from "./components/offlineOrders/syncOnline.jsx";
import TplInspectionFormOnline from "./pages/tpl-inspection-form.jsx";
import shortInspectionFormOnline from "./pages/short-inspection-form.jsx";
import ShortInspectionHistory from "./pages/short-inspection-report.jsx";

const xorEncrypt = (data, key) => {
  const encryptedData = data.split("").map((char, i) => {
    const keyChar = key.charCodeAt(i % key.length);
    const encryptedChar = char.charCodeAt(0) ^ keyChar;
    return String.fromCharCode(encryptedChar);
  });
  return encryptedData.join("");
};

function App() {
  const { dispatch } = useAuthContext();

  const [showHeaderFooter, setShowHeaderFooter] = React.useState(true);
  const [showHeaderInspection, setShowHeaderInspection] = React.useState(true);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  React.useEffect(() => {
    const url = window.location.search;
    const authToken = extractTokenFromUrl(url, "authToken");

    const getData = async () => {
      try {
        let resp = authToken;
        let base64Url = resp.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const data = JSON.parse(jsonPayload);
        localStorage.setItem("data", JSON.stringify(data));

        const userResponse = await axios.get(`${baseUrl}/getUser`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const encryptedUserData = xorEncrypt(
          JSON.stringify(userResponse.data),
          "noman"
        );
        Cookies.set("%2515M%25", encryptedUserData, {
          expires: 7,
        });

        await dispatch({ type: "LOGIN", payload: userResponse.data });
      } catch (error) {
        console.error(error);
      }
    };

    if (authToken) {
      getData();
    }
  }, []);

  React.useEffect(() => {
    const data = Cookies.get("%2515M%25");

    const xorEncrypt = (data, key) => {
      const encryptedData = data.split("").map((char, i) => {
        const keyChar = key.charCodeAt(i % key.length);
        const encryptedChar = char.charCodeAt(0) ^ keyChar;
        return String.fromCharCode(encryptedChar);
      });
      return encryptedData.join("");
    };
    const xorDecrypt = (encryptedData, key) => {
      return xorEncrypt(encryptedData, key); // Since XOR is symmetric, encryption and decryption are the same
    };

    if (data) {
      const decryptedData = xorDecrypt(data, "noman");
      if (data) {
        dispatch({ type: "LOGIN", payload: JSON.parse(decryptedData) });
      }
    } else {
      dispatch({ type: "LOGOUT" });
    }
  }, [dispatch]);

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    setShowHeaderFooter(currentPath !== "/inspection-report");
  }, []);

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    setShowHeaderInspection(currentPath !== "/inspector-form");
  }, []);

  return (
    <>
      <Router>
        {showHeaderFooter && <Header />}
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={withRouter(Homepage)} />
          <Route
            exact
            path="/inspector-form"
            component={withRouter(InspectorForm)}
          />
          <Route
            path="/car-inspection"
            component={withRouter(VehicleInspection)}
          />
          <Route
            path="/my-orders"
            component={withRouter(UserInspectionOrders)}
          />
          <Route
            path="/bidding-pending-orders"
            component={withRouter(BiddingPendingOrders)}
          />
          <Route
            path="/inspection-orders"
            component={withRouter(AdminInspectionOrders)}
          />
          <Route
            path="/completed-orders"
            component={withRouter(Completedorders)}
          />

          <Route path="/profile" component={withRouter(MyProfile)} />
          <Route path="/offline-orders" component={withRouter(OfflineOrders)} />
          <Route
            path="/inspection-history"
            component={withRouter(InspectionHistory)}
          />
          <Route
            path="/short-inspection-reports"
            component={withRouter(ShortInspectionHistory)}
          />
          <Route
            path="/bidding-inspection-reports"
            component={withRouter(BiddingInspectionHistoryBody)}
          />
          <Route path="/inspector-form" component={withRouter(InspectorForm)} />
          <Route
            path="/inspector-online-form"
            component={withRouter(InspectorFormOnline)}
          />
          <Route
            path="/tpl-inspection-form"
            component={withRouter(TplInspectionFormOnline)}
          />
          <Route
            path="/short-inspection-form"
            component={withRouter(shortInspectionFormOnline)}
          />
          <Route
            path="/inspection-report"
            component={withRouter(InspectorReport)}
          />

          <Route path="/syncoffline" component={withRouter(OfflineSync)} />
          <Route
            path="/forgot-password"
            component={withRouter(ForgotPassword)}
          />
          <Route path="/reset-password" component={withRouter(ResetPassword)} />
          <Route
            path="/inspection-draft"
            component={withRouter(PendingInspections)}
          />
          <Route path="/offline-draft" component={withRouter(OfflineDraft)} />
          <Route
            path="/bidding-inspection-draft"
            component={withRouter(BiddingInspectionDraft)}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
