import * as React from "react";
import "../Styles/style.css";
import { useEffect, useState } from "react";
import axios from "axios";
// import baseUrl from "../../api/ipconfig";
import MyadsNavigation from "../ads/myads-navigation";
import UserDetails from "../ads/userDetails";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InnerLoader from "../loader/innerLoader";
import baseUrl from "../../api/ipconfig";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OfflineDraft() {
  const { user } = React.useContext(AuthContext);

  const [inspectionHistory, setInspectionHistory] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [statusMsg, setStatusMsg] = useState("");
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // const user = localStorage.getItem("data");
  // const userId = JSON.parse(user);

  useEffect(() => {
    const fetchPendingInspections = async () => {
      const getData = localStorage.getItem("inspection_draft");

      const data = JSON.parse(getData);

      //console.log(data)

      setInspectionHistory(data);
      setLoading(false);
    };
    fetchPendingInspections();
  }, [user?.id]);
  //console.log(userId, "user history");

  const handleButtonClick = (stepId) => {
    history.push(`inspector-form?inspector_Step=${stepId}`);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleBarClose}
          severity="success"
          sx={{ width: "100%" }}
        ></Alert>
      </Snackbar>
      <div className="MyAds_main">
        <UserDetails />
        <div className="container-md MyAds_body">
          <div className="row pb-5">
            <div className="col-lg-2 p-0">
              <MyadsNavigation />
            </div>
            <div className="col-lg-10 ">
              {isLoading ? (
                <InnerLoader />
              ) : inspectionHistory?.length ? (
                <>
                  <div className=" bgSecondary boxShadow mb-3 ">
                    <div className="row paymentHistory_header">
                      <div className="col-lg-2 col-2">
                        <h6>Id</h6>
                      </div>
                      <div className="col-lg-3 col-3">
                        <h6>Car Detail</h6>
                      </div>
                      <div className="col-lg-3 col-3">
                        <h6>Step</h6>
                      </div>
                      <div className="col-lg-4 col-4 text-end pe-5 ">
                        <h6>Action</h6>
                      </div>
                    </div>
                  </div>
                  {inspectionHistory?.map((item) => (
                    <div className=" bg-white paymentHistory_list boxShadow mb-3">
                      <div className="row align-items-center py-2 ms-2">
                        <div className="col-lg-2 col-2">
                          <h6> {item?.inspection_id} </h6>
                        </div>
                        <div className="col-lg-3 col-3">
                          <h6>{item?.name}</h6>
                        </div>
                        <div className="col-lg-3 col-3">
                          <h6>{item?.step}</h6>
                        </div>

                        <div className="col-lg-4 col-4 d-flex justify-content-end ">
                          <Button
                            variant="contained"
                            className=" bgSecondary color-white me-3 text-capitalize "
                            key={`${item?.inspection_id}${item?.token}`}
                            onClick={() =>
                              handleButtonClick(
                                `${item.step}&token=${item?.token}&inspection=${item?.inspection_id}`
                              )
                            }
                          >
                            Edit Form
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="adsSection">
                  <div className="No_ads text-center">
                    <p>no offline history available</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
